import { Directory } from 'types/Directory';
import { Folder } from 'types/Folder';

const makeFolder = (root: Directory, targetFolderId: string): Folder => {
  const folder: Folder = { ...root.fileMap[targetFolderId], childrens: [] };
  folder.childrens =
    folder.childrenIds?.map((childrenId) => root.fileMap[childrenId]) || [];
  return folder;
};

export default makeFolder;
