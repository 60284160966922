import { axios } from 'helpers/axios/axios';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';
import { StatisticsProps, TotalByDate } from '../types';

const getTotalViewsByFolderDate = async ({
  itemId,
  dateRange,
  selectedState,
}: StatisticsProps): Promise<TotalByDate[]> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  const baseURL = `/v1/analytics/directory/total-views-by-date`;
  const urlWithDates = `${dateFilter}&directory=${itemId}&region=${selectedState}`;
  const { data: TotalByDate } = await axios.get(`${baseURL}${urlWithDates}`);
  return TotalByDate as TotalByDate[];
};

export default getTotalViewsByFolderDate;
