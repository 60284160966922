import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

interface TotalWordsInterestByState {
  total: Record<string, number>;
}

const getWordsInterestByState = async (
  directory: string,
  dateRange: DateRange,
): Promise<TotalWordsInterestByState> => {
  const queryStringFilters = formatBaseURLWithDateRange(dateRange);
  const baseURL = `/v1/analytics/directory`;
  return await axios
    .get(
      `${baseURL}/words-interest-by-region${queryStringFilters}&directory=${directory}`,
    )
    .then((data) => data.data);
};

export default getWordsInterestByState;
