import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';

const cellStyles = {
  borderBottom: 'none',
  borderRight: '1px solid #D1D3E0',
  borderLeft: 'none',
  color: 'neutral.body',
  fontSize: 12,
  pl: { mobile: 4, desktop: 5 },
  pr: 1,
  py: 2,
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {
    pl: { mobile: 4, desktop: 5 },
  },
  '&:last-child': {
    borderRight: 'none',
  },
};

const ParticipantTableBody = () => (
  <TableBody>
    {[...Array(5)].map((_, index) => (
      <TableRow
        key={`participant-skeleton-${index}`}
        sx={{
          border: 0,
          borderBottom: '1px solid #D1D3E0',
          '&:last-child': {
            borderBottom: 'none',
          },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{ ...cellStyles, width: { desktop: 846 } }}
        >
          <Skeleton
            variant="rounded"
            height={24}
            sx={{ width: { mobile: '100%', desktop: 746 } }}
          />
        </TableCell>
        <TableCell sx={cellStyles}>
          <Skeleton
            variant="rounded"
            height={24}
            sx={{ width: { mobile: '100%', desktop: '95%' } }}
          />
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

export default ParticipantTableBody;
