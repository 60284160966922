import { ArrowRightAlt } from '@mui/icons-material';
import { Paper, Grid, Typography, Divider, useTheme } from '@mui/material';
import { differenceInCalendarMonths } from 'date-fns';
import formatDate from 'utils/formatDate';
import { DateRange, DefinedRange, Setter, NavigationAction } from '../types';
import { MARKERS } from '..';
import DefinedRanges from './DefinedRanges';
import Month from './Month';

interface Props {
  dateRange: DateRange;
  firstMonth: Date;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  minDate: Date;
  maxDate: Date;
  ranges: DefinedRange[];
  secondMonth: Date;
  setDateRange: Setter<DateRange>;
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
}

const Menu = (props: Props) => {
  const {
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    helpers,
    handlers,
  } = props;
  const { startDate, endDate } = dateRange;
  const canNavigateCloser =
    differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };
  const { palette } = useTheme();
  const startDateLabel =
    startDate != null ? formatDate(startDate, 'dd MMM yy') : 'Data inicial';
  const endDateLabel =
    endDate != null ? formatDate(endDate, 'dd MMM yy') : 'Data final';
  return (
    <Paper elevation={5} square>
      <Grid container direction="row" wrap="nowrap">
        <Grid>
          <Grid container sx={{ px: 10, py: 4 }} alignItems="center">
            <Grid item sx={{ flex: 1, textAlign: 'center' }}>
              <Typography variant="subtitle1">{startDateLabel}</Typography>
            </Grid>
            <Grid item sx={{ flex: 1, textAlign: 'center' }}>
              <ArrowRightAlt color="action" />
            </Grid>
            <Grid item sx={{ flex: 1, textAlign: 'center' }}>
              <Typography variant="subtitle1">{endDateLabel}</Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container direction="row" justifyContent="center" wrap="nowrap">
            <Month
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[true, canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
              {...commonProps}
            />
            <div style={{ borderLeft: `1px solid ${palette.action.hover}` }} />
            <Month
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[canNavigateCloser, true]}
              marker={MARKERS.SECOND_MONTH}
              {...commonProps}
            />
          </Grid>
        </Grid>
        <div style={{ marginBottom: 4 }} />
        <Grid>
          <DefinedRanges
            selectedRange={dateRange}
            ranges={ranges}
            setRange={setDateRange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Menu;
