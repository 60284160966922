import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';
import {
  DevicesResponse,
  MostUsedResolutions,
  SpeedProps,
} from './getVodStaticsInfo';

export interface FolderInfo {
  totalSpeeds: SpeedProps[];
  mostUsedResolutions: MostUsedResolutions;
  mostUsedDevices: DevicesResponse;
}

export interface GetStatisticsInfoProps {
  folderId: string;
  dateRange: DateRange;
  selectedState: string;
}

const getFolderStatisticsInfo = async ({
  folderId,
  dateRange,
  selectedState,
}: GetStatisticsInfoProps): Promise<FolderInfo> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  const dateWithNoQuestionMark = dateFilter.replace('?', '');
  const baseURL = `/v1/analytics/directory`;
  const urlWithDates = `${dateWithNoQuestionMark}&region=${selectedState}`;
  const { data: totalSpeeds } = await axios.get(
    `${baseURL}/total-speeds?directory=${folderId}&${urlWithDates}`,
  );
  const { data: mostUsedResolutions } = await axios.get(
    `${baseURL}/most-used-resolutions?directory=${folderId}&${urlWithDates}`,
  );
  const { data: mostUsedDevices } = await axios.get(
    `${baseURL}/most-used-devices?directory=${folderId}&${urlWithDates}`,
  );
  return {
    totalSpeeds,
    mostUsedResolutions,
    mostUsedDevices,
  };
};

export default getFolderStatisticsInfo;
