import { Components, Theme } from '@mui/material';

const makeMuiTextField = (theme: Theme): Components['MuiTextField'] => ({
  defaultProps: { fullWidth: true, minRows: 2, size: 'small' },
  styleOverrides: {
    root: {
      '& .MuiFormHelperText-root': { marginLeft: theme.spacing(3) },
      '& input::placeholder, & input::-webkit-input-placeholder, & textarea::placeholder, & textarea::-webkit-input-placeholder':
        {
          color: theme.palette.neutral.muted,
          opacity: 1,
        },
    },
  },
});

export default makeMuiTextField;
