import { isSameDay } from 'date-fns';
import { DateRange } from '../types';

const isSameRange = (first: DateRange, second: DateRange): boolean => {
  const { startDate: firstStart, endDate: firstEnd } = first;
  const { startDate: secondStart, endDate: secondEnd } = second;
  const hasRange =
    firstStart != null &&
    secondStart != null &&
    firstEnd != null &&
    secondEnd != null;
  if (hasRange) {
    return isSameDay(firstStart, secondStart) && isSameDay(firstEnd, secondEnd);
  }
  return false;
};

export default isSameRange;
