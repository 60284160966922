import { Stack, Typography } from '@mui/material';

const ConsumptionCardItem = ({
  title,
  value,
  disabled,
}: {
  title: string;
  value: string;
  disabled: boolean;
}) => (
  <Stack gap={2}>
    <Typography
      variant="body2"
      sx={{ color: disabled ? 'neutral.muted' : 'neutral.50' }}
    >
      {title}
    </Typography>
    <Typography
      variant="h6"
      sx={{
        color: disabled ? 'neutral.muted' : 'neutral.body',
        fontWeight: 600,
      }}
    >
      {disabled ? '-' : value}
    </Typography>
  </Stack>
);

export default ConsumptionCardItem;
