import { TotalByDate } from "../types";

const makeLineChartData = (): TotalByDate[] => [
  {
    date: '2023-05-16',
    total: 10000,
  },
  {
    date: '2023-05-17',
    total: 8000,
  },
  {
    date: '2023-05-18',
    total: 6000,
  },
  {
    date: '2023-05-19',
    total: 4000,
  },
  {
    date: '2023-05-20',
    total: 2000,
  },
  {
    date: '2023-05-21',
    total: 0,
  },
];

export default makeLineChartData;
