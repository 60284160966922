import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

interface WordsInterest {
  term: string;
  total: number;
}

const getWordsInterestByVod = async (
  vodId: string,
  selectedState: string,
  dateRange: DateRange,
): Promise<WordsInterest[]> => {
  const baseURL = `/v1/analytics/vod/${vodId}`;
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  return await axios
    .get(
      `${baseURL}/words-interest${dateFilter}&vodId=${vodId}&region=${selectedState}`,
    )
    .then((data) => data.data);
};

export default getWordsInterestByVod;
