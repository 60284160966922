import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

interface TotalImpressions {
  total: number;
}

const getTotalImpressions = async (
  vodId: string,
  dateRange: DateRange,
): Promise<TotalImpressions> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  return await axios
    .get(`/v1/analytics/vod/${vodId}/total-impressions${dateFilter}`)
    .then((data) => data.data);
};

export default getTotalImpressions;
