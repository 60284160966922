import { Box, Card, Divider, Typography } from '@mui/material';
import { Room } from '../types';
import SessionChart from './Graphics/Session';
import SessionItem from './SessionItem';

interface Props {
  expanded: boolean;
  room: Room;
}

const SessionList = ({ expanded, room }: Props) => {
  if (!expanded) {
    return <></>;
  }
  return (
    <Box sx={{ mb: 6 }}>
      <Divider sx={{ bgcolor: 'neutral.25' }} />
      <SessionChart roomName={room.roomName} sessions={room.sessions} />
      <Card sx={{ mt: 4 }}>
        <Typography
          sx={{
            color: 'neutral.muted',
            fontSize: 12,
            mb: 2,
            mt: 4,
            ml: { mobile: 4, desktop: 5 },
          }}
        >
          Sessões
        </Typography>
        {room.sessions.map((session, index) => (
          <SessionItem
            key={session.roomSessionId}
            index={index}
            session={session}
          />
        ))}
      </Card>
    </Box>
  );
};

export default SessionList;
