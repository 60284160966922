export enum TabsType {
  'General' = 'general',
  'Statistics' = 'statistics',
  'Popularity' = 'popularity',
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface Region {
  id: string;
  value: number;
}
