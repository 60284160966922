import { axiosOld } from 'helpers/axios/axios';

export interface Me {
  user: {
    _id: string;
    avatar: string;
    nome: string;
    login: string;
    cargo: string;
    cliente: string;
    diretorioRoot: string;
  };
  permissoes: string[];
}

const getMe = async (): Promise<Me> =>
  await axiosOld.get('/me').then(({ data }) => data);

export default getMe;
