import { Box, Stack, Typography } from '@mui/material';

interface SubtitleLabels {
  color: string;
  label: string;
  isVisible: boolean;
  percentage: number;
}

const SubtitleLabel = ({
  color,
  label,
  isVisible,
  percentage,
}: SubtitleLabels) => {

  return (
    <Stack
      key={label}
      alignItems="center"
      direction="row"
      sx={{
        display: isVisible ? 'flex' : 'none',
      }}
    >
      <Box
        sx={{
          bgcolor: color,
          borderRadius: '50%',
          height: 12,
          mr: 1,
          minWidth: 12,
        }}
      />
      <Typography
        sx={{
          color: 'neutral.body',
          fontSize: 12,
          fontWeight: 600
        }}
      >
        {label}:
      </Typography>
      <Typography
        sx={{
          color: 'neutral.body',
          fontSize: 12,
          ml: 1
        }}
      >
        {percentage.toFixed(2)}%
      </Typography>
    </Stack>
  );
};

export default SubtitleLabel;
