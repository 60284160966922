import { IconButton, Link, Stack } from '@mui/material';
import { KeyboardDoubleArrowRightRounded as Arrow } from '@mui/icons-material';
import spallaLogo from 'assets/png/Spalla.png';
import Context from 'components/Context';
import { SUBDOMAIN } from 'consts/subdomain';
import { useContext } from 'react';

const Header = () => {
  const { openSideMenu, menuIsSticked, setOpenSideMenu, setMenuIsSticked } =
    useContext(Context);
  const toggleMenuIsBlocked = () => {
    if (menuIsSticked) {
      setOpenSideMenu(false);
      setMenuIsSticked(false);
      return;
    }
    setMenuIsSticked(true);
  };
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent={openSideMenu ? 'space-between' : 'flex-start'}
      sx={{ px: 5 }}
    >
      {openSideMenu && (
        <Link href={`${SUBDOMAIN}/streaming`}>
          <img src={spallaLogo} alt="logo" />
        </Link>
      )}
      <IconButton
        sx={{ p: 0, '&:hover': { bgcolor: 'common.white' } }}
        onClick={toggleMenuIsBlocked}
      >
        <Arrow
          sx={{
            color: menuIsSticked ? 'primary.main' : 'neutral.muted',
            fontSize: 26,
            transform: openSideMenu ? 'rotate(-180deg)' : '0',
            transition: '.3s',
            path: { '&:first-of-type': { opacity: 0.3 } },
            '&:hover': { color: 'primary.main' },
          }}
        />
      </IconButton>
    </Stack>
  );
};

export default Header;
