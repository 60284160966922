import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import AppTemplate from 'components/AppTemplate';
import MeProvider from 'components/MeProvider';
import PermissionsProvider from 'components/PermissionsProvider';
import theme from 'components/Theme/theme';
import queryClient from 'helpers/queryClient';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <MeProvider>
        <PermissionsProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<AppTemplate />} />
            </Routes>
          </BrowserRouter>
        </PermissionsProvider>
      </MeProvider>
    </ThemeProvider>
  </QueryClientProvider>
);
