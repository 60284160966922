import * as am5 from '@amcharts/amcharts5';
import am5locales_pt from '@amcharts/amcharts5/locales/pt_BR';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Box, Stack, useTheme } from '@mui/material';
import { useLayoutEffect } from 'react';
import makeLineChartData from '../mocks/makeLineChartData';
import { TotalByDate } from '../types';

interface Props {
  tooltipTitle?: string;
  data: TotalByDate[];
  hasZoom?: boolean;
  yAxisIsInSeconds?: boolean;
  xAxisIsinSeconds?: boolean;
  yAxisIsPercents?: boolean;
  itemId?: string;
}

const dateFormat = 'dd MMM. YYYY';

// TODO: colocar maximo 5 linhas
// TODO: tirar paddings laterais do gráfico
// TODO: yLabel deve ser . ao invés de virgula para separar os milhares
// TODO: xLabel deve mostrar 04 Abril 2023
// TODO: adicionar linha vertical na label
// TODO: melhorar tooltip
// TODO: linhas de cima e baixo não estão com 2 width
const LineChart = ({
  tooltipTitle = '{date.formatDate()}',
  data,
  hasZoom,
  yAxisIsInSeconds,
  xAxisIsinSeconds,
  itemId,
  yAxisIsPercents,
}: Props) => {
  const newData = data || makeLineChartData();
  const hasData = !!data;
  const chartId = itemId ?? '';
  const { palette } = useTheme();
  useLayoutEffect(() => {
    const root = am5.Root.new(`viewsByDateChart-${chartId}`);
    if (root._logo != null) {
      root._logo.dispose();
    }
    root.locale = am5locales_pt;
    root.numberFormatter.setAll({ numberFormat: '#.###,00' });
    root.dateFormatter.set('dateFormat', dateFormat);
    root.setThemes([am5themesAnimated.new(root)]);
    const chart = root.container.children.push(am5xy.XYChart.new(root, {}));
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, { behavior: 'none' }),
    );
    cursor.lineY.set('visible', false);
    cursor.lineX.set('visible', hasData);
    const xDateAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
        dateFormats: {
          day: dateFormat,
        },
      }),
    );
    const xSecondsAxis = chart.xAxes.push(
      am5xy.DurationAxis.new(root, {
        baseUnit: 'second',
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );

    const yAxis = chart.yAxes.push(
      yAxisIsInSeconds
        ? am5xy.DurationAxis.new(root, {
            baseUnit: 'second',
            renderer: am5xy.AxisRendererY.new(root, {}),
          })
        : am5xy.ValueAxis.new(root, {
            maxPrecision: 0,
            renderer: am5xy.AxisRendererY.new(root, {
              minGridDistance: 20,
            }),
            numberFormat: yAxisIsPercents ? "#'%'" : undefined,
          }),
    );
    xSecondsAxis.get('renderer').grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    xSecondsAxis.get('renderer').labels.template.setAll({
      fill: am5.color(palette.neutral['50']),
      fontFamily: 'Poppins',
      fontSize: 12,
      fontWeight: '400',
      paddingRight: 8,
    });
    xDateAxis.get('renderer').grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    xDateAxis.get('renderer').labels.template.setAll({
      fill: am5.color(palette.neutral['50']),
      fontFamily: 'Poppins',
      fontSize: 12,
      fontWeight: '400',
      paddingRight: 8,
    });
    yAxis.get('renderer').grid.template.setAll({
      stroke: am5.color(palette.neutral['25']),
      strokeOpacity: 1,
      strokeWidth: 1,
    });
    yAxis.get('renderer').labels.template.setAll({
      fill: am5.color(palette.neutral.main),
      fontFamily: 'Poppins',
      fontSize: 12,
      fontWeight: '400',
      paddingRight: 8,
    });
    if (yAxisIsInSeconds ?? xAxisIsinSeconds) {
      root.durationFormatter.setAll({
        baseUnit: 'second',
        durationFormat: 'hh:mm:ss',
        durationFields: yAxisIsInSeconds ? ['valueY'] : ['valueX'],
      });
    }
    const tooltipValue = yAxisIsPercents ? '{total}%' : '{total}';
    const tooltipLabel = yAxisIsInSeconds
      ? '{total.formatDuration()}'
      : tooltipValue;
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis: xAxisIsinSeconds ? xSecondsAxis : xDateAxis,
        yAxis,
        valueYField: 'total',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          html: hasData
            ? `
            <div style="background-color: #FFFFFF; font-family: 'Poppins'; font-size: 12px; width: 150px">
              <div style="background-color: #EBEDF3; color: #575757; height: 30px; padding-left: 8px; padding-top: 8px;">${tooltipTitle}</div>
              <div style="color: #575757; height: 30px; padding-left: 8px;  padding-top: 8px;">
                ${yAxisIsInSeconds ? '{total.formatDuration()}' : tooltipLabel}
              </div>
            </div>
          `
            : '',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }),
      }),
    );
    if (hasZoom) {
      chart.set(
        'scrollbarX',
        am5.Scrollbar.new(root, {
          dx: -20,
          height: 20,
          marginBottom: 20,
          orientation: 'horizontal',
        }),
      );
      const scrollbarX = chart.get('scrollbarX');
      if (scrollbarX) {
        scrollbarX.startGrip.set('scale', 0.7);
        scrollbarX.endGrip.set('scale', 0.7);
      }
    }
    const sanitizedData = newData.map(({ date, total }) => ({
      date: xAxisIsinSeconds
        ? Number(date)
        : new Date(`${date} 00:00:00`).getTime(),
      total,
      strokeSettings: {
        stroke: palette.primary.main,
      },
    }));
    series.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: hasData ? 4 : 0,
          fill: series.get('fill'),
        }),
      });
    });
    series.strokes.template.setAll({
      strokeWidth: 2,
      visible: hasData,
      templateField: 'strokeSettings',
    });
    series.data.setAll(sanitizedData);
    return () => root?.dispose();
  }, []);
  return (
    <Stack>
      <Box
        id={`viewsByDateChart-${chartId}`}
        sx={{ height: 230, minHeight: 180 }}
      />
    </Stack>
  );
};

export default LineChart;
