import { BarChartRounded as ChartIcon } from '@mui/icons-material';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import DateRangePickerComponent from 'components/DatePicker/DateRangePicker';
import { startOfMonth } from 'date-fns';
import { useCallback, useState } from 'react';
import { DateRange } from 'types/Date';
import { Session as ISession } from '../../../types';
import ChartSummary from '../components/ChartSummary';
import EmptyChart from '../components/EmptyChart';
import Chart from './components/Chart';
import { groupSessionsByDate } from './utils/formatData';

interface Props {
  roomName: string;
  sessions: ISession[];
}

const Session = ({ roomName, sessions }: Props) => {
  const [dateRange, setDateRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
  });
  const getStreamerMinutes = sessions.map(
    ({ totalStreamerMinutes }) => totalStreamerMinutes,
  );
  const countTotalSessionDuration = getStreamerMinutes.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );
  const defaultSelectedSession = {
    isGlobal: true,
    totalStreamerMinutes: countTotalSessionDuration,
  };
  const [selectedSession, setSelectedSession] = useState(
    defaultSelectedSession,
  );
  const resetMinutesInformation = useCallback(
    () => setSelectedSession(defaultSelectedSession),
    [],
  );
  const updateSelectedSession = useCallback(
    (value: number) =>
      setSelectedSession({
        isGlobal: false,
        totalStreamerMinutes: value,
      }),
    [],
  );
  const chartData = groupSessionsByDate(sessions);
  const filterChartDataByRangeDate = (dateRange: DateRange) => {
    const getStartTime = new Date(dateRange.startDate).getTime();
    const getEndTime = new Date(dateRange.endDate).getTime();

    return chartData.filter((item) => {
      const startedAt = new Date(item.startedAt).getTime();
      return startedAt >= getStartTime && startedAt <= getEndTime;
    });
  };
  const dataFilteredByDateRange = filterChartDataByRangeDate(dateRange);
  return (
    <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <CardContent sx={{ p: 0 }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ px: { mobile: 4, desktop: 5 }, py: 3 }}
        >
          <Typography
            sx={{
              color: 'neutral.body',
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            Gráfico de sessões
          </Typography>
          <DateRangePickerComponent
            dateRange={dateRange}
            changeDateRange={setDateRange}
          />
        </Stack>
        <Stack
          direction={{ mobile: 'column', desktop: 'row' }}
          gap={{ mobile: 2, desktop: 0 }}
          sx={{
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: 'neutral.25',
            height: { desktop: 226 },
            p: { mobile: 4, desktop: 5 },
            pb: 0,
            width: '100%',
          }}
        >
          <ChartSummary
            icon={ChartIcon}
            isGlobal={selectedSession.isGlobal}
            onReset={resetMinutesInformation}
            title={`${Intl.NumberFormat('pt-BR').format(
              selectedSession.totalStreamerMinutes,
            )}min`}
            description={
              selectedSession.isGlobal
                ? 'Duração total das sessões'
                : 'Duração total da sessão'
            }
          />
          {dataFilteredByDateRange.length > 0 ? (
            <Chart
              data={dataFilteredByDateRange}
              roomName={roomName}
              updateSelectedSession={updateSelectedSession}
            />
          ) : (
            <EmptyChart callback={resetMinutesInformation} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Session;
