import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

interface WatchedTime {
  durationInSeconds: number;
}

const getWatchedTime = async (
  vodId: string,
  dateRange: DateRange,
): Promise<WatchedTime> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  return await axios
    .get(`/v1/analytics/vod/${vodId}/average-watched-time${dateFilter}`)
    .then((data) => data.data);
};

export default getWatchedTime;
