import { Info as InfoIcon } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

const EmptyList = () => (
  <Stack alignItems="center" justifyContent="center" sx={{ height: 391 }}>
    <InfoIcon sx={{ color: 'neutral.muted', height: 49, width: 45 }} />
    <Typography
      sx={{ color: 'neutral.muted', fontSize: 20, fontWeight: 700, mt: 3 }}
    >
      Sem registro
    </Typography>
    <Typography
      sx={{
        color: 'neutral.50',
        fontSize: 14,
        maxWidth: 352,
        mt: 2,
        textAlign: 'center',
      }}
    >
      Nenhum vídeo registrado no sistema.
    </Typography>
  </Stack>
);

export default EmptyList;
