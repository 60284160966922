import {
  BarChartRounded as BarChartRoundedIcon,
  LocalPolice as SpallaIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  Tv as TvIcon,
} from '@mui/icons-material';
import { SUBDOMAIN } from 'consts/subdomain';
import { Item } from './types';

export const items: Item[] = [
  {
    id: 'content',
    icon: <TvIcon sx={{ fontSize: 24 }} />,
    title: 'Conteúdo',
    requires: ['showStreaming', 'showVOD', 'showHistory'],
    subItems: [
      {
        title: 'Transmissões',
        href: `${SUBDOMAIN}/streaming`,
        requires: 'showStreaming',
      },
      {
        title: 'Broadcast',
        href: `${SUBDOMAIN}/broadcast`,
        requires: ['broadcast', 'simulcast'],
      },
      {
        title: 'Histórico de transmissões',
        href: `${SUBDOMAIN}/historic`,
        requires: 'showHistory',
      },
      {
        title: 'Vídeo on Demand',
        href: `${SUBDOMAIN}/vod/dashboard`,
        requires: 'showVOD',
      },
    ],
  },
  {
    id: 'management',
    icon: <PersonIcon sx={{ fontSize: 24 }} />,
    title: 'Gestão',
    requires: ['showUsers', 'showRoles', 'spalla-dev', 'showFinancial'],
    subItems: [
      {
        title: 'Usuários',
        href: `${SUBDOMAIN}/users`,
        requires: 'showUsers',
      },
      {
        title: 'Registros',
        href: `${SUBDOMAIN}/records`,
        isBeta: true,
      },
      {
        title: 'Times',
        href: `${SUBDOMAIN}/teams`,
        requires: 'gerenciarTimes',
      },
      {
        title: 'Cargos',
        href: `${SUBDOMAIN}/roles`,
        requires: 'showRoles',
      },
      {
        title: 'Permissões',
        href: `${SUBDOMAIN}/permissions`,
        requires: 'spalla-dev',
      },
      {
        title: 'Financeiro',
        href: `${SUBDOMAIN}/billing`,
        requires: 'showFinancial',
      },
    ],
  },
  {
    id: 'analytics',
    icon: <BarChartRoundedIcon sx={{ fontSize: 24 }} />,
    title: 'Analytics',
    requires: [
      'showStreaming',
      'showVOD',
      'showHistory',
      'showStreamingAnalytics',
    ],
    subItems: [
      {
        title: 'Video on Demand',
        href: `/vod*`,
        isInternalLink: true,
        requires: [
          'showStreaming',
          'showVOD',
          'showHistory',
          'showStreamingAnalytics',
        ],
      },
      {
        title: 'Spalla Studio',
        href: `/studio`,
        isInternalLink: true,
        isBeta: true,
        requires: ['spalla-dev', 'spalla_studio'],
      },
    ],
    isBeta: false,
  },
  {
    id: 'settings',
    icon: <SettingsIcon sx={{ fontSize: 24 }} />,
    title: 'Integração',
    requires: ['api', 'showAccountSettings', 'spalla-dev', 'integrations'],
    subItems: [
      {
        title: 'Chaves de API',
        href: `${SUBDOMAIN}/api-keys`,
        requires: 'api',
      },
      {
        title: 'Integrações',
        href: `${SUBDOMAIN}/integrations`,
        requires: ['spalla-dev', 'integrations'],
      },
      {
        title: 'Configurações',
        href: `${SUBDOMAIN}/account/settings`,
        requires: 'showAccountSettings',
      },
    ],
  },
  {
    id: 'spalla',
    icon: <SpallaIcon sx={{ fontSize: 24 }} />,
    title: 'Spalla',
    requires: 'spalla-dev',
    subItems: [
      {
        title: 'Clientes',
        href: `${SUBDOMAIN}/clients`,
      },
    ],
  },
];
