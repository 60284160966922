import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

interface TotalViews {
  total: number;
}

const getTotalViews = async (
  vodId: string,
  dateRange: DateRange,
): Promise<TotalViews> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  return await axios
    .get(`/v1/analytics/vod/${vodId}/total-views${dateFilter}`)
    .then((data) => data.data);
};

export default getTotalViews;
