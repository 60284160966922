import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { ReactNode } from 'react';
import MetricsCard from './MetricsCard/MetricsCard';
import MetricsCardSkeleton from './MetricsCard/MetricsCardSkeleton';

interface GetMetricCardsProps {
  disabled?: boolean;
  icon: ReactNode;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  title: string;
  value: string | number;
}

const GetMetricCards = ({
  disabled,
  icon,
  isLoading,
  sx,
  title,
  value,
}: GetMetricCardsProps) => {
  if (isLoading) {
    return <MetricsCardSkeleton sx={sx} />;
  }
  return (
    <MetricsCard
      title={title}
      icon={icon}
      value={value}
      disabled={disabled}
      sx={{ width: 'calc(50% - 8px)', ...sx }}
    />
  );
};

export default GetMetricCards;
