import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import ParticipantTableBody from './ParticipantTableBody';

const labels = ['Participantes', 'Participação'];

const ParticipantTable = () => (
  <Table>
    <TableHead>
      <TableRow
        sx={{
          '&:first-of-type': {
            borderTop: 'none',
          },
        }}
      >
        {labels.map((label) => (
          <TableCell
            key={label}
            sx={{
              color: 'neutral.muted',
              border: '1px solid #D1D3E0',
              borderLeft: 'none',
              borderTop: 'none',
              fontSize: 12,
              pr: 1,
              pb: 2,
              pl: { mobile: 4, desktop: 5 },
              pt: 4,
              textTransform: 'uppercase',
              '&:first-of-type': {
                pl: { mobile: 4, desktop: 5 },
              },
              '&:last-child': {
                borderRight: 'none',
              },
              '&:before': {
                display: 'none',
              },
            }}
          >
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <ParticipantTableBody />
  </Table>
);

export default ParticipantTable;
