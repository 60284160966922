import { Stack } from '@mui/material';
import { useEffect, useRef } from 'react';
import { Player } from 'theoplayer';
import './styles.css';

interface Source {
  src: string;
  type: string;
}

interface Sources {
  sources: Source[];
}

interface PlayerProps {
  source: Sources;
}

declare global {
  interface Window {
    THEOplayer: Player;
  }
}

const PlayerWrapper = ({ source }: PlayerProps) => {
  const playerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const createPlayer = () => {
      if (playerRef.current) {
        const player = new window.THEOplayer.Player(playerRef.current, {
          libraryLocation: process.env.REACT_APP_LIBRARY_LOCATION_URL,
          license: process.env.REACT_APP_LIBRARY_PLAYER_LICENSE,
        });
        player.source = source;
        const rewind = () => {
          player.currentTime -= 5;
        };
        const forward = () => {
          player.currentTime = Number(player.currentTime) + 5;
        };
        const toggleMute = () => {
          player.muted = !player.muted;
        };
        const togglePlay = () => {
          if (player.paused) {
            player.play();
          } else {
            player.pause();
          }
        };
        const preventStandardHotKeyActions = (event: KeyboardEvent) => {
          event.stopPropagation();
          event.preventDefault();
        };
        const keyActionsMapper = {
          ' ': togglePlay,
          ArrowLeft: rewind,
          ArrowRight: forward,
          m: toggleMute,
        };
        const getPressedKey = (event: KeyboardEvent) => {
          const pressedKey = event.key;
          const action =
            keyActionsMapper[pressedKey as keyof typeof keyActionsMapper];
          if (
            action &&
            pressedKey !== 'Control' &&
            pressedKey !== 'Alt' &&
            pressedKey !== 'Shift'
          ) {
            action();
            preventStandardHotKeyActions(event);
          }
        };
        const playerFocused = () => {
          if (playerRef.current?.contains(document.activeElement)) {
            document.addEventListener('keydown', getPressedKey);
          } else {
            document.removeEventListener('keydown', getPressedKey);
          }
        };
        const mouseInPlayer = () => {
          document?.addEventListener('keydown', getPressedKey);
          playerRef.current?.addEventListener('mouseleave', mouseOutPlayer);
        };
        const mouseOutPlayer = () => {
          playerRef.current?.removeEventListener('mouseleave', mouseOutPlayer);
          document.removeEventListener('keydown', getPressedKey);
        };
        const enableShortcuts = (method: string) => {
          switch (method) {
            case 'mouseOver':
              playerRef.current?.addEventListener('mouseenter', mouseInPlayer);
              break;
            case 'playerFocused':
              document?.addEventListener('focus', playerFocused, true);
              break;
            default:
              document?.addEventListener('keydown', getPressedKey);
          }
        };
        enableShortcuts('mouseOver');
      }
    };
    createPlayer();
  }, [source]);
  return (
    <Stack
      id="bm-player"
      className="theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer"
      ref={playerRef}
    />
  );
};

export default PlayerWrapper;
