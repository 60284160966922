/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContext } from 'react';

interface ContextType {
  selectedVodId: string;
  changeSelectedVodId: (id: string) => void;
}

const Context = createContext({} as ContextType);

export default Context;
