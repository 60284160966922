import { axios } from 'helpers/axios/axios';
import { Pagination } from 'modules/VoD/interfaces/Pagination';
import { Vod } from 'modules/VoD/interfaces/vod';

export const getRecentlyViewedVideos = async (
  page: number,
  direction: 'asc' | 'desc',
): Promise<{ items: Vod[]; pagination: Pagination }> =>
  await axios
    .get(
      `/v1/videos/vod?page=${page}&limit=10&sort=last_playback_at,${direction}`,
    )
    .then(({ data }) => data);
