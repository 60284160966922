import { intervalToDuration } from 'date-fns';

interface Duration {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const isBiggerThanZero = (value: number) => Boolean(value);
const alreadyHaveThreeValues = (value: string[]) => value.length === 3;

const formatIntervalDuration = (minutesToParser: number): string => {
  const secondsToParser = minutesToParser * 60;
  const { years, months, days, hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: secondsToParser * 1000,
  }) as Duration;
  const timeInterval: string[] = [];
  const showDays =
    isBiggerThanZero(days) ||
    isBiggerThanZero(years) ||
    isBiggerThanZero(months);
  if (isBiggerThanZero(years)) {
    timeInterval.push(`${years}a`);
  }
  if (isBiggerThanZero(months) || isBiggerThanZero(years)) {
    timeInterval.push(`${months}m`);
  }
  if (showDays) {
    timeInterval.push(`${days}d`);
  }
  const timeMeasures = [
    { value: hours, label: 'h' },
    { value: minutes, label: 'min' },
    { value: seconds, label: 's' },
  ];
  timeMeasures.forEach((time) => {
    if (!alreadyHaveThreeValues(timeInterval)) {
      timeInterval.push(`${time.value}${time.label}`);
    }
  });
  return timeInterval.join(' ');
};

export default formatIntervalDuration;
