import { Card, CardContent, Skeleton } from '@mui/material';

const MapChartSkeleton = () => (
  <Card>
    <CardContent>
      <Skeleton variant="text" width={155} height={25} />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={369}
        sx={{ mt: 2 }}
      />
    </CardContent>
  </Card>
);

export default MapChartSkeleton;
