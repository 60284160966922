import { ViewHeadlineRounded as ViewHeadlineIcon } from '@mui/icons-material';
import {
  Drawer,
  Grid,
  IconButton,
  Link,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import spallaLogo from 'assets/png/Spalla.png';
import Context from 'components/Context';
import { useCallback, useContext, useState } from 'react';
import { Context as MeProvider } from '../MeProvider/MeProvider';
import Footer from './components/Footer';
import Header from './components/Header';
import Items from './components/Items';
import ItemsSkeletons from './components/ItemsSkeletons';
import { SUBDOMAIN } from 'consts/subdomain';

const SideMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const [openedItemkey, setOpenedItemkey] = useState('');
  const { openSideMenu, menuIsSticked, setOpenSideMenu } = useContext(Context);
  const { me } = useContext(MeProvider);
  const openMenuHandler = () => {
    setOpenSideMenu(true);
  };
  const closeMenuHandler = () => {
    const menuIsntBlocked = !menuIsSticked;
    if (menuIsntBlocked) {
      setOpenedItemkey('');
      setOpenSideMenu(false);
    }
  };
  const openSideMenuOnHover = () =>
    openSideMenu ? () => null : openMenuHandler;
  const switchSubMenuAccordion = useCallback(
    (id: string) => {
      const sameItem = id === openedItemkey;
      if (sameItem) {
        setOpenedItemkey('');
        return;
      }
      setOpenedItemkey(id);
    },
    [openedItemkey],
  );
  const rootFolderId = me ? me.user.diretorioRoot : '';
  return (
    <>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={openSideMenu}
        onMouseEnter={openSideMenuOnHover()}
        onMouseLeave={closeMenuHandler}
        onClose={() => {
          if (isMobile) {
            closeMenuHandler();
          }
        }}
        PaperProps={{
          square: true,
          sx: {
            borderRadius: '0',
            minHeight: '100vh',
            overflow: 'hidden',
            transition: '0.2s',
            marginLeft: { mobile: openSideMenu ? 0 : '-100%', desktop: 0 },
            width: { mobile: 264, desktop: openSideMenu ? 264 : 70 },
            position: 'fixed',
          },
        }}
      >
        <Grid
          container
          direction="column"
          sx={{ height: '100%', mt: { mobile: 4, desktop: 0 } }}
        >
          {!isMobile && (
            <Grid item sx={{ height: 64, mb: 4, py: 4 }}>
              <Header />
            </Grid>
          )}
          <Grid item sx={{ maxHeight: '55%', overflowY: 'auto' }}>
            {me ? (
              <Items
                openedItemkey={openedItemkey}
                onSwitchAccordion={switchSubMenuAccordion}
                rootFolderId={rootFolderId}
              />
            ) : (
              <ItemsSkeletons />
            )}
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Drawer>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          bgcolor: 'common.white',
          display: { desktop: 'none' },
          height: 56,
          px: 4,
          py: 1,
        }}
      >
        <Link href={`${SUBDOMAIN}/streaming`}>
          <img src={spallaLogo} alt="logo" height={36} />
        </Link>
        <IconButton
          onClick={openMenuHandler}
          sx={{ p: 0, '&:hover': { bgcolor: 'common.white' } }}
        >
          <ViewHeadlineIcon
            sx={{
              color: 'primary.main',
              fontSize: 26,
            }}
          />
        </IconButton>
      </Stack>
    </>
  );
};

export default SideMenu;
