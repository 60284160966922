import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  index: number;
  value: number;
  sx?: SxProps<Theme>;
}

const TabPanel = ({ children, value, index, sx, ...other }: Props) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3, ...sx }}>{children}</Box>}
  </div>
);

export default TabPanel;
