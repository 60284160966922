import { AxiosError } from 'axios';
import { redirectToLogin } from 'helpers/axios/axios';
import { StatusCodes } from 'http-status-codes';

const checkAuthorization = async (error: AxiosError) => {
  const statusCode = error?.response?.status;
  const isUnauthorized =
    statusCode === StatusCodes.UNAUTHORIZED ||
    statusCode === StatusCodes.FORBIDDEN;
  if (isUnauthorized) {
    redirectToLogin();
  }
  return await Promise.reject(error);
};

export default checkAuthorization;
