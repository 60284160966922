import { colors, Components, Theme } from '@mui/material';
import hexToRgba from 'utils/hexToRgba';

const makeMuiButton = (theme: Theme): Components['MuiButton'] => ({
  defaultProps: {
    color: 'primary',
    disableElevation: true,
    disableRipple: false,
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      borderRadius: theme.shape.borderRadius,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: 600,
      height: theme.shape.size.medium,
      textTransform: 'none',
    },
    contained: {
      backgroundColor: theme.palette.primary.main,
      color: colors.common.white,
      '&:hover': { backgroundColor: theme.palette.primary.dark },
    },
    sizeSmall: {
      fontSize: theme.typography.body3.fontSize,
      height: theme.shape.size.small,
    },
    sizeMedium: {
      fontSize: theme.typography.body2.fontSize,
      height: theme.shape.size.medium,
    },
    sizeLarge: {
      fontSize: theme.typography.body1.fontSize,
      height: theme.shape.size.large,
    },
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        border: 'none',
        '&:hover': { border: 'none' },
      },
    },
    {
      props: { color: 'error' },
      style: {
        backgroundColor: theme.palette.error.main,
        color: colors.common.white,
        '&:hover': { backgroundColor: theme.palette.error.dark },
      },
    },
    {
      props: { color: 'primary', variant: 'outlined' },
      style: {
        backgroundColor: theme.palette.primary[50],
        border: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary[100],
          border: 'none',
        },
      },
    },
    {
      props: { color: 'error', variant: 'outlined' },
      style: {
        backgroundColor: theme.palette.error[50],
        color: theme.palette.error.main,
        '&:hover': { backgroundColor: theme.palette.error.light },
      },
    },
    {
      props: { variant: 'text' },
      style: {
        backgroundColor: 'none',
        color: theme.palette.neutral.body,
        '&:hover': {
          backgroundColor: hexToRgba(theme.palette.neutral.body, 0.1),
        },
      },
    },
    {
      props: { color: 'info' },
      style: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white,
        '&:hover': { backgroundColor: theme.palette.info.dark },
      },
    },
    {
      props: { color: 'warning' },
      style: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white,
        '&:hover': { backgroundColor: theme.palette.warning.dark },
      },
    },
    {
      props: { color: 'warning', variant: 'outlined' },
      style: {
        backgroundColor: theme.palette.warning[50],
        color: theme.palette.warning.main,
        '&:hover': { backgroundColor: theme.palette.warning.light },
      },
    },
  ],
});

export default makeMuiButton;
