import { FilterAlt as FilterIcon } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import DateRangePickerComponent from 'components/DatePicker/DateRangePicker';
import { MouseEvent, useContext, useState } from 'react';
import SearchInput from './SearchInput';
import Context from '../Context';

const Toolbar = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { dateRange, setDateRange } = useContext(Context);
  const openFilderModal = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const closeFilterModal = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  return (
    <Card sx={{ minHeight: 70 }}>
      <CardContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          height: '100%',
          justifyContent: 'space-between',
          px: { mobile: 4, desktop: 5 },
          width: '100%',
        }}
      >
        <Typography sx={{ color: 'neutral.body', fontWeight: 600 }}>
          Analytics de estúdio
        </Typography>
        <Stack alignItems="center" direction="row" gap={2}>
          <SearchInput />
          <Button
            color="primary"
            variant="outlined"
            sx={{ minHeight: 37, minWidth: 45 }}
            onClick={openFilderModal}
          >
            <FilterIcon />
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={closeFilterModal}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Card sx={{ width: 240 }}>
              <CardContent>
                <Typography sx={{ fontSize: 12, mb: 1 }}>
                  Selecionar datas
                </Typography>
                <DateRangePickerComponent
                  dateRange={dateRange}
                  changeDateRange={setDateRange}
                />
              </CardContent>
            </Card>
          </Popover>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Toolbar;
