/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContext, Dispatch, SetStateAction } from 'react';

interface TemplateContext {
  menuIsSticked: boolean;
  openSideMenu: boolean;
  setOpenSideMenu: Dispatch<SetStateAction<boolean>>;
  setMenuIsSticked: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext({} as TemplateContext);

export default Context;
