import { Components, Theme } from '@mui/material';

const makeMuiSwitch = (theme: Theme): Components['MuiSwitch'] => ({
  defaultProps: { disableRipple: true },
  styleOverrides: {
    root: {
      height: 24,
      padding: 0,
      width: 42,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        transform: 'translate(5px, 5px)',
      },
      '& .MuiSwitch-switchBase:hover': { backgroundColor: 'transparent' },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translate(22px, 5px)',
      },
      '& .MuiSwitch-switchBase.Mui-checked:hover': {
        backgroundColor: 'transparent !important',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.common.white,
        height: 15,
        width: 15,
      },
      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.neutral.light,
        borderRadius: 100,
        opacity: 1,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        opacity: 1,
      },
    },
  },
});

export default makeMuiSwitch;
