import { AccessTimeFilled as ClockIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Vod } from 'modules/VoD/interfaces/vod';
import { useMemo } from 'react';

interface Props {
  video: Vod;
}

const ListItem = ({ video }: Props) => {
  const memoizedVideoDate = useMemo(
    () => (
      <Stack alignItems="center" direction="row" gap={1}>
        <ClockIcon color="primary" sx={{ fontSize: 20 }} />
        <Typography
          sx={{ color: 'neutral.body', fontSize: 12, fontWeight: 400 }}
        >
          {format(new Date(video.lastPlaybackAt), 'dd/MM/yyyy')}
        </Typography>
        <Typography sx={{ color: 'neutral.50', fontSize: 12, fontWeight: 400 }}>
          {format(new Date(video.lastPlaybackAt), 'HH:mm')} (GMT -3)
        </Typography>
      </Stack>
    ),
    [video],
  );
  return (
    <>
      <Grid container sx={{ height: { mobile: 'auto', tablet: 48 } }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          flexWrap="nowrap"
          item
          gap={5}
          mobile={12}
          tablet={6}
        >
          <Avatar
            src={video.assets.thumbnail}
            variant="rounded"
            sx={{ height: 48, width: 48 }}
          />
          <Box sx={{ maxWidth: '80%' }}>
            <Typography
              sx={{
                color: 'neutral.body',
                fontSize: 14,
                fontWeight: 400,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {video.title}
            </Typography>
            <Chip
              size="small"
              label={memoizedVideoDate}
              sx={{ display: { tablet: 'none' }, mt: 1 }}
            />
          </Box>
        </Grid>
        <Grid
          container
          item
          mobile
          sx={{ display: { mobile: 'none', tablet: 'flex' } }}
        >
          <Chip size="small" label={memoizedVideoDate} />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default ListItem;
