import { Card, CardContent, Typography } from '@mui/material';
import CardBackground from 'assets/jpg/CardBackground.jpg';

const ForbiddenPage = () => (
  <Card
    sx={{
      alignItems: 'center',
      backgroundImage: `url(${CardBackground})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      minHeight: '90vh',
      mt: 5,
      p: 11,
    }}
  >
    <CardContent>
      <Typography
        sx={{ color: 'info.main', fontSize: 132, fontWeight: 'bold', mb: 7  }}
      >
        Oops!
      </Typography>
      <Typography
        sx={{
          color: 'neutral.body',
          fontSize: 32.5,
          fontWeight: 'bold',
          mb: 3,
        }}
      >
        Parece que você não pode andar por aqui
      </Typography>
      <Typography sx={{ color: 'neutral.body', fontSize: 19.5 }}>
        Entre em contato com o administrador da sua conta e solicite acesso.
      </Typography>
    </CardContent>
  </Card>
);

export default ForbiddenPage;
