import { createTheme } from '@mui/material/styles';
import makeMuiButton from './factories/makeMuiButton';
import makeMuiCard from './factories/makeMuiCard';
import makeMuiCardContent from './factories/makeMuiCardContent';
import makeMuiChip from './factories/makeMuiChip';
import makeMuiIconButton from './factories/makeMuiIconButton';
import makeMuiInputBase from './factories/makeMuiInputBase';
import makeMuiLinearProgress from './factories/makeMuiLinearProgress';
import makeMuiOutlinedInput from './factories/makeMuiOutlinedInput';
import makeMuiPagination from './factories/makeMuiPagination';
import makeMuiPaginationItem from './factories/makeMuiPaginationItem';
import makeMuiPaper from './factories/makeMuiPaper';
import makeMuiPopover from './factories/makeMuiPopover';
import makeMuiStep from './factories/makeMuiStep';
import makeMuiStepLabel from './factories/makeMuiStepLabel';
import makeMuiStepper from './factories/makeMuiStepper';
import makeMuiSvgIcon from './factories/makeMuiSvgIcon';
import makeMuiSwitch from './factories/makeMuiSwitch';
import makeMuiTableCell from './factories/makeMuiTableCell';
import makeMuiTableRow from './factories/makeMuiTableRow';
import makeMuiTextField from './factories/makeMuiTextField';
import makeMuiTooltip from './factories/makeMuiTooltip';
import makeMuiTypography from './factories/makeMuiTypography';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3: React.CSSProperties;
  }
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
  }
  interface Theme {
    shape: {
      borderRadius: number;
      size: { small: number; medium: number; large: number };
    };
  }
  interface ThemeOptions {
    shape: {
      borderRadius: number;
      size: { small: number; medium: number; large: number };
    };
  }
  interface PaletteColor {
    800: string;
    700: string;
    600: string;
    500: string;
    400: string;
    300: string;
    100: string;
    50: string;
  }
  interface Palette {
    neutral: {
      main: string;
      body: string;
      light: string;
      muted: string;
      75: string;
      50: string;
      25: string;
    };
  }
  interface PaletteOptions {
    neutral: {
      main: string;
      body: string;
      light: string;
      muted: string;
      75: string;
      50: string;
      25: string;
    };
  }
  interface TypographyVariants {
    body3: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3: React.CSSProperties;
  }
}

const theme = createTheme({
  breakpoints: { values: { mobile: 0, tablet: 768, desktop: 1200 } },
  typography: {
    fontFamily: 'Poppins',
    h5: {
      fontSize: '1.5rem',
    },
    h6: {
      fontSize: '1.25rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    body3: {
      fontSize: '0.75rem',
    },
    overline: {
      fontSize: '0.625rem',
    },
  },
  palette: {
    primary: {
      main: '#3699FF',
      dark: '#3579DD',
      light: '#72B8FF',
      100: '#BCE2FF',
      50: '#E1F0FF',
    },
    secondary: { main: '#1F1F1F' },
    success: {
      main: '#1BC5BD',
      dark: '#26B5AB',
      light: '#80C9C3',
      100: '#B1DEDA',
      50: '#C9F7F5',
    },
    error: {
      main: '#F64E60',
      dark: '#EC2A43',
      light: '#FFAAB2',
      50: '#FFE2E5',
    },
    warning: {
      main: '#FFA800',
      dark: '#FB8F00',
      light: '#FFE2B4',
      50: '#FFF4DE',
    },
    info: {
      main: '#8950FC',
      dark: '#632AEC',
      light: '#E6C4FF',
      50: '#EEE5FF',
    },
    neutral: {
      main: '#1F1F1F',
      body: '#3F4254',
      75: '#575757',
      50: '#7E8299',
      muted: '#B5B5C3',
      25: '#D1D3E0',
      light: '#F3F6F9',
    },
  },
  spacing: [0, 4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 96],
  shape: { borderRadius: 4, size: { small: 30, medium: 37, large: 44 } },
});

theme.components = {
  MuiButton: makeMuiButton(theme),
  MuiCard: makeMuiCard(theme),
  MuiCardContent: makeMuiCardContent(theme),
  MuiChip: makeMuiChip(theme),
  MuiIconButton: makeMuiIconButton(theme),
  MuiInputBase: makeMuiInputBase(theme),
  MuiLinearProgress: makeMuiLinearProgress(theme),
  MuiOutlinedInput: makeMuiOutlinedInput(theme),
  MuiPagination: makeMuiPagination(),
  MuiPaginationItem: makeMuiPaginationItem(theme),
  MuiPaper: makeMuiPaper(),
  MuiPopover: makeMuiPopover(),
  MuiStep: makeMuiStep(theme),
  MuiStepLabel: makeMuiStepLabel(theme),
  MuiStepper: makeMuiStepper(),
  MuiSvgIcon: makeMuiSvgIcon(theme),
  MuiSwitch: makeMuiSwitch(theme),
  MuiTableCell: makeMuiTableCell(theme),
  MuiTableRow: makeMuiTableRow(theme),
  MuiTextField: makeMuiTextField(theme),
  MuiTooltip: makeMuiTooltip(theme),
  MuiTypography: makeMuiTypography(theme),
};

export default theme;
