import { axios } from 'helpers/axios/axios';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';
import { StatisticsProps } from '../types';

interface RetentionProps {
  currentTime: number;
  views: number;
  retention: number;
}

const getRetentionByMinutes = async ({
  itemId,
  dateRange,
  selectedState,
}: StatisticsProps): Promise<RetentionProps[]> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  const baseURL = `/v1/analytics/vod/${itemId}/public-retention`;
  const urlWithDates = `${dateFilter}&region=${selectedState}`;
  const { data: TotalByDate } = await axios.get(`${baseURL}${urlWithDates}`);
  return TotalByDate as RetentionProps[];
};

export default getRetentionByMinutes;
