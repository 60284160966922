import {
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';
import { Vod } from '../../../interfaces/vod';
import { DateRange } from '../../../types';
import getMostViewedVods from '../services/getMostViewedVods';
import EmptyList from './EmptyList';
import MostViewedVodsContext from './MostViewedVodsContext';
import MostViewedVodsItem from './MostViewedVodsItem';
import MostViewedVodsItemSkeletons from './MostViewedVodsItemSkeletons';

interface Props {
  allStatesValue: string;
  itemId: string;
  dateRange: DateRange;
  selectedState: string;
}

const MostViewedVodsList = ({
  allStatesValue,
  itemId,
  dateRange,
  selectedState,
}: Props) => {
  const { changeSelectedVodId } = useContext(MostViewedVodsContext);
  const newSelectedState =
    selectedState === allStatesValue ? '' : selectedState;
  const { isLoading, data: mostVods } = useQuery(
    [
      'mostVods',
      itemId,
      dateRange.startDate.toISOString(),
      dateRange.endDate.toISOString(),
      newSelectedState,
    ],
    async () => await getMostViewedVods(itemId, dateRange, newSelectedState),
    {
      onSuccess: (data) => changeSelectedVodId(data[0]?.videoId),
    },
  );
  const firstList = mostVods ? [...mostVods].slice(0, 5) : [];
  const secondList = mostVods ? [...mostVods].slice(5, 10) : [];
  const renderVods = useMemo(
    () => (vodList: Vod[], startIndex: number) =>
      vodList.map((currentVod, index) => (
        <MostViewedVodsItem
          key={currentVod.videoId}
          vod={currentVod}
          index={index + startIndex}
        />
      )),
    [],
  );
  if (isLoading || !mostVods) {
    return (
      <Card>
        <CardContent>
          <Grid container gap={2} sx={{ mt: 4 }}>
            <Grid item desktop={true}>
              {[...Array(4)].map(() => (
                <MostViewedVodsItemSkeletons />
              ))}
            </Grid>
            <Grid item desktop={true}>
              {[...Array(4)].map(() => (
                <MostViewedVodsItemSkeletons />
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
  const haveVods = mostVods.length > 0;
  return (
    <Card>
      <CardContent>
        <Stack alignItems="center" direction="row" gap={1}>
          <Typography sx={{ color: 'neutral.body', fontWeight: 600 }}>
            Vídeos mais vistos
          </Typography>
          <Tooltip title="Os vídeos mais vistos são atualizados regularmente para refletir as preferências e tendências dos usuários.">
            <InfoIcon sx={{ color: 'neutral.muted' }} />
          </Tooltip>
        </Stack>
        {haveVods ? (
          <Grid container gap={2} sx={{ mt: 4 }}>
            <Grid item desktop={true}>
              {renderVods(firstList, 1)}
            </Grid>
            <Grid item desktop={true}>
              {renderVods(secondList, 6)}
            </Grid>
          </Grid>
        ) : (
          <EmptyList />
        )}
      </CardContent>
    </Card>
  );
};

export default MostViewedVodsList;
