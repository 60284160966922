import {
  AccessTime as AccessTimeIcon,
  Person as PersonIcon,
  RemoveRedEyeRounded as EyeIcon,
  TouchAppRounded as TouchAppIcon,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import { QueryFunction, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import formatNumber from 'utils/formatNumber';
import GetMetricCards from '../../../components/GetMetricCards';
import SubtitleActivationPercentageCard from '../../../components/SubtitleActivationPercentageCard';
import SubtitleActivationSkeleton from '../../../components/SubtitleActivationSkeleton';
import getPercentageActivatedSearch from '../../../services/getPercentageActivatedSearch';
import { DateRange } from '../../../types';
import formatIntervalDuration from '../../../utils/formatIntervalDuration';
import getTotalImpressions from '../services/getTotalImpressions';
import getTotalViews from '../services/getTotalViews';
import getUnicViewers from '../services/getUnicViewers';
import getWatchedTime from '../services/getWatchedTime';

interface MostViewedVodsInfoProps {
  dateRange: DateRange;
  vodId: string;
}

interface QueryResult<T> {
  isLoading: boolean;
  data?: T;
}

const MostViewedVodsInfo = ({ dateRange, vodId }: MostViewedVodsInfoProps) => {
  const dependencies = [
    vodId,
    dateRange.startDate.toISOString(),
    dateRange.endDate.toISOString(),
  ];
  const useDataQuery = <T,>(
    newDependencies: string[],
    queryFn: QueryFunction<T, string[]>,
  ): QueryResult<T> => {
    const result: UseQueryResult<T> = useQuery(
      [...dependencies, ...newDependencies],
      queryFn,
      {
        enabled: !!vodId,
      },
    );
    return {
      isLoading: vodId ? result.isLoading : false,
      data: result.data,
    };
  };
  const { isLoading: totalViewsIsLoading, data: totalViews } = useDataQuery(
    ['getTotalViews'],
    async () => await getTotalViews(vodId, dateRange),
  );
  const { isLoading: totalImpressionsIsLoading, data: totalImpressions } =
    useDataQuery(
      ['getTotalImpressions'],
      async () => await getTotalImpressions(vodId, dateRange),
    );
  const { isLoading: watchedTimeIsLoading, data: watchedTime } = useDataQuery(
    ['getWatchedTime'],
    async () => await getWatchedTime(vodId, dateRange),
  );
  const { isLoading: unicViewersIsLoading, data: unicViewers } = useDataQuery(
    ['getUnicViewers'],
    async () => await getUnicViewers(vodId, dateRange),
  );
  const { isLoading: activatedSearchIsLoading, data: totalActivatedSearch } =
    useDataQuery(
      ['activatedSearch'],
      async () =>
        await getPercentageActivatedSearch({
          itemId: vodId,
          dateRange,
          selectedState: '',
          isDir: false,
        }),
    );
  const watchedTimeMinutes = useMemo(
    () => formatIntervalDuration(watchedTime?.durationInSeconds ?? 0),
    [watchedTime],
  );
  const cardsInfo = [
    {
      title: 'Visualizações',
      icon: <EyeIcon />,
      value: formatNumber({ number: totalViews?.total ?? 0, precision: 1 }),
      isLoading: totalViewsIsLoading && !!vodId,
      disabled: !vodId,
    },
    {
      title: 'Duração total assistida',
      icon: <AccessTimeIcon />,
      value: watchedTimeMinutes,
      isLoading: watchedTimeIsLoading && !!vodId,
      disabled: !vodId,
    },
    {
      title: 'Espectadores únicos',
      icon: <PersonIcon />,
      value: formatNumber({ number: unicViewers?.total ?? 0, precision: 1 }),
      isLoading: unicViewersIsLoading && !!vodId,
      disabled: !vodId,
    },
    {
      title: 'Impressões',
      icon: <TouchAppIcon />,
      value: formatNumber({
        number: totalImpressions?.total ?? 0,
        precision: 1,
      }),
      isLoading: totalImpressionsIsLoading && !!vodId,
      disabled: !vodId,
    },
  ];
  return (
    <Stack>
      {activatedSearchIsLoading ? (
        <SubtitleActivationSkeleton width="100%" />
      ) : (
        <SubtitleActivationPercentageCard
          data={totalActivatedSearch}
          sx={{ width: '100%' }}
        />
      )}
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        justifyContent="space-between"
        gap={4}
        sx={{ mt: 4 }}
      >
        {cardsInfo.map((card) => (
          <GetMetricCards
            isLoading={card.isLoading}
            key={card.title}
            title={card.title}
            icon={card.icon}
            value={card.value}
            disabled={card.disabled}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default MostViewedVodsInfo;
