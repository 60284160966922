import { Components, Theme } from '@mui/material';

const makeMuiLinearProgress = (
  theme: Theme,
): Components['MuiLinearProgress'] => ({
  defaultProps: { variant: 'determinate' },
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.neutral.light,
      borderRadius: 100,
      height: theme.spacing(1),
      overflow: 'hidden',
    },
  },
  variants: [
    {
      props: { color: 'error' },
      style: { backgroundColor: theme.palette.error.light },
    },
  ],
});

export default makeMuiLinearProgress;
