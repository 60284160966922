import axios, { AxiosInstance } from 'axios';
import addSpallaToken from './interceptors/request/addSpallaToken';
import checkAuthorization from './interceptors/response/checkAuthorization';

const makeAxios = (baseURL: string): AxiosInstance => {
  const axiosHelper = axios.create({
    baseURL,
    timeout: 0,
  });
  axiosHelper.interceptors.request.use(addSpallaToken);
  axiosHelper.interceptors.response.use((config) => config, checkAuthorization);
  return axiosHelper;
};

export default makeAxios;
