import { Box, Skeleton, Stack } from '@mui/material';

const DonutChartSkeleton = () => (
  <Stack alignItems="center" direction="row">
    <Stack gap={8} sx={{ height: '100%', position: 'relative' }}>
      <Skeleton variant="text" sx={{ fontWeight: 600 }} />
      <Skeleton variant="circular" sx={{ minHeight: 195, width: 195 }} />
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '50%',
          height: 145,
          left: '25px',
          position: 'absolute',
          top: '98px',
          width: 145,
        }}
      />
    </Stack>
    <Stack gap={2}>
      {[...Array(5)].map(() => (
        <Skeleton
          key={Math.random()}
          variant="text"
          sx={{ fontWeight: 600, height: 20, ml: 6, width: 85 }}
        />
      ))}
    </Stack>
  </Stack>
);

export default DonutChartSkeleton;
