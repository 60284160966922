import { ExpandMore as ArrowIcon } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';

interface Props {
  sortDirection: 'asc' | 'desc';
  setSortDirection: (direction: 'asc' | 'desc') => void;
}

const ListHeader = ({ sortDirection, setSortDirection }: Props) => (
  <>
    <Grid container sx={{ mb: 2 }}>
      <Grid
        item
        mobile={6}
        sx={{ color: 'neutral.muted', fontSize: 12, fontWeight: 600 }}
      >
        NOME
      </Grid>
      <Grid
        container
        direction="row"
        gap={1}
        item
        mobile
        sx={{ color: 'neutral.muted', fontSize: 12, fontWeight: 600 }}
      >
        ÚLTIMA VISUALIZAÇÃO
        <ArrowIcon
          color="primary"
          onClick={() =>
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
          }
          sx={{
            cursor: 'pointer',
            fontSize: 16,
            transition: 'transform ease 0.2s',
            transform:
              sortDirection === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      </Grid>
    </Grid>
    <Divider sx={{ mb: 4 }} />
  </>
);

export default ListHeader;
