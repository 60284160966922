import { Card, CardContent, Skeleton, Stack, SxProps, Theme } from '@mui/material';

const MetricsCardSkeleton = ({sx}: {sx?: SxProps<Theme>;}) => (
  <Card
    sx={{
      height: 107,
      width: 'calc(50% - 16px)',
      ...sx
    }}
  >
    <CardContent>
      <Stack sx={{mb: 2,width: 400}}>
        <Skeleton variant="text" width={150} height={15} />
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        gap={3}
      >
        <Skeleton variant="circular" width={25} height={25} />
        <Skeleton variant="text" width={210} height={20} />
      </Stack>
    </CardContent>
  </Card>
);

export default MetricsCardSkeleton;
