export const qualityMostUseds = [
  {
    id: 1,
    color: 'info.main',
    label: '1080p',
  },
  {
    id: 4,
    color: 'warning.main',
    label: '720p',
  },
  {
    id: 6,
    color: 'success.main',
    label: '480p',
  },
  {
    id: 7,
    color: 'error.main',
    label: '360p',
  },
  {
    id: 8,
    color: 'primary.main',
    label: '240p',
  },
];

export const speedsMostUseds = [
  {
    id: 1,
    color: 'info.main',
    label: '2,5x',
  },
  {
    id: 2,
    color: '#BCE2FF',
    label: '2x',
  },
  {
    id: 3,
    color: '#FFAAB2',
    label: '1,75x',
  },
  {
    id: 4,
    color: 'warning.main',
    label: '1,5x',
  },
  {
    id: 5,
    color: '#B1DEDA',
    label: '1,25x',
  },
  {
    id: 6,
    color: 'success.main',
    label: 'Normal',
  },
  {
    id: 7,
    color: 'error.main',
    label: '0,75x',
  },
  {
    id: 8,
    color: 'primary.main',
    label: '0,5x',
  },
];
