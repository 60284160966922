import { Components, Theme } from '@mui/material';

const makeMuiStepLabel = (theme: Theme): Components['MuiStepLabel'] => ({
  styleOverrides: {
    root: {
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: 'solid',
      borderBottomWidth: 4,
      color: theme.palette.primary.main,
      fontSize: theme.typography.body3.fontSize,
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(9),
      '&.Mui-disabled': {
        borderBottomColor: theme.palette.neutral.light,
      },
      '&.Mui-disabled .MuiStepLabel-iconContainer .MuiTypography-root': {
        color: theme.palette.neutral.muted,
      },
      '& .MuiStepLabel-label': {
        color: theme.palette.neutral.muted,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        marginTop: 6,
      },
      '& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.MuiStepLabel-completed':
        {
          color: theme.palette.primary.main,
        },
      '&.Mui-disabled .MuiStepLabel-label': {
        color: theme.palette.neutral.muted,
      },
    },
  },
});

export default makeMuiStepLabel;
