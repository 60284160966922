import { Components, Theme } from '@mui/material';

const makeMuiTooltip = (theme: Theme): Components['MuiTooltip'] => ({
  defaultProps: { arrow: true },
  styleOverrides: {
    tooltip: {
      fontSize: theme.typography.body3.fontSize,
      fontWeight: 'normal',
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  },
});

export default makeMuiTooltip;
