import { Card, CardContent, Stack, Skeleton } from '@mui/material';

const SubtitleActivationSkeleton = ({ width }: { width?: number | string }) => (
  <Card sx={{ cursor: 'not-allowed', height: 107, width: width ?? 'calc(50% - 16px)' }}>
    <CardContent>
      <Stack alignItems="center" direction="row" gap={5}>
        <Skeleton variant="circular" width={75} height={75} />
        <Stack>
          <Skeleton variant="text" width={155} height={15} />
          <Skeleton variant="text" width={55} height={15} />
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default SubtitleActivationSkeleton;
