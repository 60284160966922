import { Grid } from '@mui/material';
import { useContext } from 'react';
import Context from '../../../Context';
import MapChart from './MapChart';
import WordsInterestList from './WordsInterestList';

const WordsInterest = () => {
  const { allStatesValue, dateRange, selectedState } = useContext(Context);
  return (
    <Grid container gap={4} sx={{ mt: 3 }}>
      <Grid item desktop={5}>
        <WordsInterestList
          allStatesValue={allStatesValue}
          dateRange={dateRange}
          selectedState={selectedState}
        />
      </Grid>
      <Grid item desktop>
        <MapChart />
      </Grid>
    </Grid>
  );
};

export default WordsInterest;
