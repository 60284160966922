import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Route } from '../interfaces/route';

const useRouteIsBlocked = (routes: Route[]): boolean => {
  const [routeIsBlocked, setRouteIsBlocked] = useState(false);
  const { pathname } = useLocation();
  const getPageType = (): string => {
    const pathElements = pathname.split('/');
    const type = pathElements.pop();
    return type ?? '';
  };
  useEffect(() => {
    const type = getPageType();
    const foundRoute = routes.find((route: Route) => route.path.includes(type));
    if (type && foundRoute) {
      setRouteIsBlocked(foundRoute.blocked);
    }
  }, [getPageType, routes]);
  return routeIsBlocked;
};

export default useRouteIsBlocked;
