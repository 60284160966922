import { Divider, Grid } from '@mui/material';

const ListHeader = () => (
  <>
    <Grid container sx={{ mb: 2 }}>
      <Grid
        item
        mobile={6}
        sx={{
          color: 'neutral.muted',
          display: { mobile: 'none', tablet: 'flex' },
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        IDENTIFICAÇÃO
      </Grid>
      <Grid
        container
        direction="row"
        gap={1}
        item
        mobile
        sx={{ color: 'neutral.muted', fontSize: 12, fontWeight: 600 }}
      >
        CURTIDAS
      </Grid>
    </Grid>
    <Divider sx={{ mb: 4 }} />
  </>
);

export default ListHeader;
