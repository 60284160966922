import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, OutlinedInput, SxProps, Theme } from '@mui/material';
import { useContext, useState } from 'react';
import Context from '../Context';

interface Props {
  sx?: SxProps<Theme>;
}

const SearchInput = ({ sx }: Props) => {
  const { searchName, setSearchName } = useContext(Context);
  const [name, setName] = useState(searchName);
  return (
    <OutlinedInput
      placeholder="Pesquisar por estúdio"
      value={name}
      onKeyUp={(evt) => {
        const submitWithEnter = evt.code === 'Enter';
        if (submitWithEnter) {
          setSearchName(name);
        }
      }}
      onChange={(event) => setName(event.target.value)}
      endAdornment={
        <InputAdornment
          position="end"
          onClick={() => setSearchName(name)}
          sx={{ cursor: 'pointer' }}
        >
          <SearchIcon />
        </InputAdornment>
      }
      sx={{
        height: 37,
        width: 345,
        ...sx,
      }}
    />
  );
};

export default SearchInput;
