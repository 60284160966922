import { Favorite as LikeIcon } from '@mui/icons-material';
import { Chip, Divider, Grid, Link, Typography } from '@mui/material';
import { SUBDOMAIN } from 'consts/subdomain';
import { Vod } from 'modules/VoD/interfaces/vod';

interface Props {
  video: Vod;
}

const ListItem = ({ video }: Props) => (
  <>
    <Grid
      container
      sx={{
        flexDirection: { mobile: 'column', desktop: 'row' },
        height: { mobile: 'auto', tablet: 48 },
      }}
    >
      <Grid
        alignItems="center"
        container
        item
        gap={5}
        mobile={12}
        tablet={6}
        sx={{ paddingRight: 6 }}
      >
        <Typography
          sx={{
            color: 'neutral.body',
            fontSize: 14,
            fontWeight: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Link
            target="_blank"
            href={`${SUBDOMAIN}/vod/${video.videoId}`}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            {video.videoTitle}
          </Link>
        </Typography>
      </Grid>
      <Grid container item mobile tablet={6}>
        <Chip
          size="small"
          icon={<LikeIcon />}
          label={video.likes}
          sx={{ mt: 1 }}
        />
      </Grid>
    </Grid>
    <Divider sx={{ my: 2 }} />
  </>
);

export default ListItem;
