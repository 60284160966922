import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

interface WordsInterest {
  term: string;
  total: number;
}

const getWordsInterest = async (
  directory: string,
  selectedState: string,
  dateRange: DateRange,
): Promise<WordsInterest[]> => {
  const baseURL = `/v1/analytics/directory`;
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  return await axios
    .get(
      `${baseURL}/words-interest${dateFilter}&directory=${directory}&region=${selectedState}`,
    )
    .then((data) => data.data);
};

export default getWordsInterest;
