import { axios } from 'helpers/axios/axios';

interface TotalCountVoD {
  total: number;
}

const getTotalCountVod = async (): Promise<TotalCountVoD> => {
  const baseURL = `/v1/analytics/video-count`;
  const { data: totalCountVoD } = await axios.get(baseURL);
  return totalCountVoD;
};

export default getTotalCountVod;
