import { Folder as FolderIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import ModalToChooseFolder from './ModalToChooseFolder';

const ChooseFolder = () => {
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => setOpenModal(true);
  const closeModalHandler = useCallback(() => setOpenModal(false), []);
  return (
    <>
      <Button variant="outlined" onClick={openModalHandler}>
        <FolderIcon />
      </Button>
      {openModal && <ModalToChooseFolder open={openModal} onClose={closeModalHandler} />}
    </>
  );
};

export default ChooseFolder;
