import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { MONTHS } from 'consts/months';
import { DAYS_COUNT } from 'consts/weekDays';
import { getMonth, getYear, setMonth, setYear } from 'date-fns';
import { useMemo } from 'react';
import generateYears from '../utils/generateYears';

interface Props {
  date: Date;
  nextDisabled: boolean;
  prevDisabled: boolean;
  setDate: (date: Date) => void;
  onClickNext: () => void;
  onClickPrevious: () => void;
}

const Header = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
}: Props) => {
  const handleMonthChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setDate(setMonth(date, parseInt(event.target.value as string)));
  };
  const handleYearChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setDate(setYear(date, parseInt(event.target.value as string)));
  };
  const month = getMonth(date) as any; // TODO: the select is not accepting types other than "object""
  const year = getYear(date) as any; // TODO: the select is not accepting types other than "object"
  const yearsList = useMemo(
    () =>
      generateYears(date, DAYS_COUNT).map((year) => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      )),
    [],
  );
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sx={{ padding: 1 }}>
        <IconButton
          sx={{
            padding: 2,
            '&:hover': {
              background: 'none',
            },
          }}
          disabled={prevDisabled}
          onClick={onClickPrevious}
        >
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
      <Grid item>
        <Select
          value={month}
          MenuProps={{ disablePortal: true }}
          sx={{ fieldset: { display: 'none' } }}
          onChange={handleMonthChange}
        >
          {MONTHS.map((month: string, index: number) => (
            <MenuItem key={month} value={index}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Select
          value={year}
          MenuProps={{ disablePortal: true }}
          sx={{ fieldset: { display: 'none' } }}
          onChange={handleYearChange}
        >
          {yearsList}
        </Select>
      </Grid>
      <Grid item sx={{ padding: 1 }}>
        <IconButton
          sx={{
            padding: 2,
            '&:hover': {
              background: 'none',
            },
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
        >
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;
