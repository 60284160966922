interface FormatNumberProps {
  number: number;
  precision: number;
}

const formatNumber = ({
  number,
  precision,
}: FormatNumberProps): number | string => {
  const thresholds = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];
  const foundThreshold = thresholds.find(
    (thresholdItem) => Math.abs(number) >= thresholdItem.threshold,
  );
  if (foundThreshold) {
    const shortNumber = (number / foundThreshold.threshold).toFixed(precision);
    const isIntNumber = shortNumber.endsWith('0');
    const formatted = isIntNumber
      ? (number / foundThreshold.threshold).toFixed(0) + foundThreshold.suffix
      : shortNumber + foundThreshold.suffix;
    return formatted;
  }
  return number;
};

export default formatNumber;
