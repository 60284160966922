import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Skeleton,
  Stack,
} from '@mui/material';

const Skeletons = () => (
  <Stack direction="row" sx={{ bgcolor: 'neutral.light', height: '100vh' }}>
    <Grid
      alignItems="center"
      container
      direction="column"
      justifyContent="space-between"
      sx={{
        bgcolor: '#fff',
        height: '100vh',
        py: 4,
        width: 70,
      }}
    >
      <Grid item sx={{ height: 64, width: '100%' }}>
        <Stack alignItems="center" justifyContent="center">
          <Skeleton variant="rounded" width={26} height={26} />
          <Stack gap={4} sx={{ mt: 8 }}>
            {[...Array(5)].map((_, index) => (
              <Skeleton key={index} variant="rounded" width={40} height={45} />
            ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item>
        <Skeleton variant="rounded" width={26} height={26} />
      </Grid>
    </Grid>
    <Stack sx={{ mt: 4, width: '100%', px: 5 }}>
      <Card sx={{ height: 'calc(100% - 16px)', width: '100%' }}>
        <CardContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.15)' }} />
        </CardContent>
      </Card>
    </Stack>
  </Stack>
);

export default Skeletons;
