import { Refresh as RefreshIcon } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';

interface Props {
  title?: string;
  description?: string;
  onRetry: () => any;
}

const RetryCard = ({
  title = 'Algo deu errado',
  description = 'Não foi possível encontrar essa informação.',
  onRetry,
}: Props) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ height: 226, width: '100%' }}
    >
      <RefreshIcon sx={{ color: 'neutral.muted', fontSize: 50, mb: 3 }} />
      <Typography
        sx={{ color: 'neutral.body', fontSize: 16, fontWeight: 600, mb: 2 }}
      >
        {title}
      </Typography>
      <Typography sx={{ color: 'neutral.50', fontSize: 14, mb: 3 }}>
        {description}
      </Typography>
      <Button
        color="error"
        sx={{ maxWidth: 308, width: '100%' }}
        onClick={onRetry}
      >
        Tentar novamente
      </Button>
    </Stack>
  );
};

export default RetryCard;
