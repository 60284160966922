import { Lock as LockIcon } from '@mui/icons-material';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import {
  PathMatch,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import Context from '../Context';
import { TabsType } from '../types';

interface Tab {
  title: string;
  type: TabsType;
  blocked: boolean;
}

const AnalyticsTab = ({ tab }: { tab: Tab }) => {
  const { title, type, blocked } = tab;
  const { itemId } = useContext(Context);
  const { params } = useMatch(`/vod/:id/:type`) as PathMatch;
  const tabIsSelected = params.type === type;
  const { search } = useLocation();
  const navigate = useNavigate();
  const changeTab = () => {
    if (!blocked) {
      navigate(`/vod/${itemId}/${type}${search}`);
    }
  };
  const getColor = () => {
    if (blocked) {
      return 'neutral.muted';
    }
    if (tabIsSelected) {
      return 'common.white';
    }
    return 'neutral.body';
  };
  return (
    <Card
      sx={{
        bgcolor: tabIsSelected ? 'primary.main' : 'common.white',
        cursor: blocked ? 'blocked' : 'pointer',
        height: 53,
        width: '100%',
      }}
      onClick={changeTab}
    >
      <CardContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          height: '100%',
          justifyContent: 'center',
          '&:last-child': { paddingBottom: 4 },
        }}
      >
        {blocked && <LockIcon sx={{ color: 'neutral.muted', fontSize: 16 }} />}
        <Typography
          variant="body2"
          sx={{
            color: getColor(),
            fontWeight: 600,
            lineHeight: 0,
          }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

const TypeSelector = () => {
  const { isDir } = useContext(Context);
  const tabs = useMemo(
    () => [
      { title: 'Geral', type: TabsType.General, blocked: false },
      { title: 'Estatísticas', type: TabsType.Statistics, blocked: false },
      {
        title: 'Popularidade (VoD)',
        type: TabsType.Popularity,
        blocked: false,
      },
    ],
    [isDir],
  );
  const memorizeRenderedTabs = useMemo(
    () => tabs.map((tab) => <AnalyticsTab key={tab.title} tab={tab} />),
    [tabs],
  );
  return (
    <Stack alignItems="center" direction="row" gap={2}>
      {memorizeRenderedTabs}
    </Stack>
  );
};

export default TypeSelector;
