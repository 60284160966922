import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

interface UnicViews {
  total: number;
}

const getUnicViewers = async (
  vodId: string,
  dateRange: DateRange,
): Promise<UnicViews> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  return await axios
    .get(
      `/v1/analytics/vod/${vodId}/total-unique-viewers-by-video${dateFilter}`,
    )
    .then((data) => data.data);
};

export default getUnicViewers;
