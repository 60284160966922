import { IconButton, Stack, Typography, useTheme } from '@mui/material';

interface Props {
  filled?: boolean;
  isToday?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  value: number | string;
  onClick?: () => void;
  onHover?: () => void;
}

const Day = ({
  filled = false,
  isToday = false,
  highlighted = false,
  disabled = false,
  startOfRange = false,
  endOfRange = false,
  value,
  onClick,
  onHover,
}: Props) => {
  const { palette } = useTheme();
  const leftBorderRadius = { borderRadius: startOfRange ? '50% 0 0 50%' : '' };
  const rightBorderRadius = { borderRadius: endOfRange ? '0 50% 50% 0' : '' };
  const dayBorderRadius = {
    ...leftBorderRadius,
    ...rightBorderRadius,
  };
  const isEnable = !disabled;
  const mustBeFilled = filled;
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor:
          isEnable && highlighted ? palette.action.hover : 'none',
        ...dayBorderRadius,
      }}
    >
      <IconButton
        sx={{
          backgroundColor:
            isEnable && mustBeFilled ? palette.primary.dark : 'none',
          border:
            isEnable && isToday ? `1px solid ${palette.primary.dark}` : 'none',
          height: 36,
          padding: 0,
          '&:hover': {
            backgroundColor:
              isEnable && mustBeFilled ? palette.primary.dark : 'none',
          },
          width: 36,
        }}
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onHover}
      >
        <Typography
          sx={{
            color: isEnable ? palette.common.black : palette.neutral.muted,
            lineHeight: 1.6,
          }}
          variant="body2"
        >
          {value}
        </Typography>
      </IconButton>
    </Stack>
  );
};

export default Day;
