import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';
import { TotalViewsByState } from '../types';

const getFolderMapChart = async (
  itemId: string,
  dateRange: DateRange,
): Promise<TotalViewsByState> => {
  const queryStringFilters = formatBaseURLWithDateRange(dateRange);
  const baseURL = `/v1/analytics/directory`;
  return await axios
    .get(
      `${baseURL}/total-views-by-region${queryStringFilters}&directory=${itemId}`,
    )
    .then((data) => data.data);
};

export default getFolderMapChart;
