import { Components, Theme } from '@mui/material';

const makeMuiTableCell = (theme: Theme): Components['MuiTableCell'] => ({
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${theme.palette.info.light}`,
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      position: 'relative',
      '&:first-of-type': { paddingLeft: 0, '&::before': { display: 'none' } },
      '&::before': {
        backgroundColor: theme.palette.info.light,
        content: '" "',
        height: 'calc(100% - 32px)',
        left: 0,
        position: 'absolute',
        top: 16,
        width: 1,
      },
    },
  },
});

export default makeMuiTableCell;
