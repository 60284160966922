import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

export interface ContentInfo {
  consumption: {
    storageMinutes: number;
    transcodeMinutes: number;
  };
  impressions: {
    total: number;
  };
  lastView: {
    trackedAt: string;
  };
  totalViews: {
    total: number;
  };
}

const getContentInfo = async (
  contentId: string,
  dateRange: DateRange,
  selectedState: string,
): Promise<ContentInfo> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  const baseURL = `/v1/analytics/vod/${contentId}`;
  const urlWithDates = `${dateFilter}&region=${selectedState}`;
  const dateWithNoDayFilter = formatBaseURLWithDateRange(dateRange, 'MM/yyyy');
  const { data: consumption } = await axios.get(
    `/v1/analytics/vod/${contentId}/consumed${dateWithNoDayFilter}`,
  );
  const { data: impressions } = await axios.get(
    `${baseURL}/total-impressions${urlWithDates}`,
  );
  const { data: lastView } = await axios.get(
    `${baseURL}/last-view${urlWithDates}`,
  );
  const { data: totalViews } = await axios.get(
    `${baseURL}/total-views${urlWithDates}`,
  );
  return {
    consumption,
    impressions,
    lastView,
    totalViews,
  };
};

export default getContentInfo;
