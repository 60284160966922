import { Error as ErrorIcon, Info as InfoIcon } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import EmptyState from 'components/EmptyState';
import RetryCard from 'components/RetryCard';
import { useState } from 'react';
import ListHeader from './components/ListHeader';
import ListItem from './components/ListItem';
import ListItemSkeleton from './components/ListItemSkeleton';
import { getRecentlyViewedVideos } from './services';

const RecentlyViewed = () => {
  const [page, setPage] = useState(1);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const { isLoading, data, isError, refetch } = useQuery(
    ['recentlyViewed', page, sortDirection],
    async () => await getRecentlyViewedVideos(page, sortDirection),
  );
  const changePage = (_: unknown, value: number) => {
    setPage(value);
  };
  const emptyList = !isLoading && data && !data.items.length;
  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Stack alignItems="center" direction="row" gap={1} sx={{ mb: 4 }}>
          <Typography sx={{ color: 'neutral.body', fontWeight: 600 }}>
            Visto recentemente
          </Typography>
          <Tooltip title="Vídeos ordenados pela data da última visualização">
            <InfoIcon sx={{ color: 'neutral.muted' }} />
          </Tooltip>
        </Stack>
        {isError && <RetryCard onRetry={refetch} />}
        {!emptyList && !isError && (
          <ListHeader
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />
        )}
        {emptyList && !isError && (
          <EmptyState
            icon={ErrorIcon}
            title="Sem registro"
            description="Nenhum vídeo registrado no sistema."
          />
        )}
        {isLoading && !data
          ? [...Array(3)].map((_, index: number) => (
              <ListItemSkeleton key={`ListItemSkeleton-${index}`} />
            ))
          : data?.items.map((currentVideo) => (
              <ListItem key={currentVideo.videoId} video={currentVideo} />
            ))}
        {!isLoading && data && !!data.items.length && (
          <Pagination
            color="primary"
            page={page}
            count={data.pagination.pagesTotal}
            sx={{ mt: 4 }}
            onChange={changePage}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default RecentlyViewed;
