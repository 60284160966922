import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import Context from '../../../Context';
import MetricButton from './MetricButton';

interface Metric {
  label: string;
}

const metricsList: Metric[] = [
  {
    label: 'Vídeos mais vistos',
  },
  {
    label: 'Palavras de interesse',
  },
  {
    label: 'Visto recentemente',
  },
  {
    label: 'Vídeos mais curtidos',
  },
];

interface Props {
  selectedTabIndex: number;
  onChangeSelectedTabIndex: (index: number) => void;
}

const SelectMetric = ({
  selectedTabIndex,
  onChangeSelectedTabIndex,
}: Props) => {
  const { isDir } = useContext(Context);
  const memoizedMetricsList = useMemo(
    () =>
      metricsList.map((metric, index) => {
        const isSelected = selectedTabIndex === index;
        const analyzedItemIsNotVisible =
          !isDir && metric.label === 'Vídeos mais vistos';
        if (analyzedItemIsNotVisible) {
          return <></>;
        }
        return (
          <MetricButton
            key={metric.label}
            label={metric.label}
            isSelected={isSelected}
            onClick={() => onChangeSelectedTabIndex(index)}
          />
        );
      }),
    [metricsList, selectedTabIndex, onChangeSelectedTabIndex],
  );
  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Stack
          alignItems="center"
          direction="row"
          sx={{ height: '100%', width: '100%' }}
        >
          <Typography sx={{ color: 'neutral.body', fontWeight: 600, mr: 4 }}>
            Selecionar métrica:
          </Typography>
          {memoizedMetricsList}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SelectMetric;
