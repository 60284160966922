import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MouseEventHandler, useState } from 'react';

interface Props {
  name: string;
}

const RoomName = ({ name }: Props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const addedSpaces = name.replaceAll('-nbsp-', ' ');
  const closeTooltip: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setOpen(false);
  };
  const openTooltip: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setOpen(true);
  };
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Tooltip
        PopperProps={{
          disablePortal: true,
          sx: {
            '.MuiTooltip-tooltip': {
              p: 0,
              maxWidth: { mobile: 343, desktop: '100%' },
              width: { mobile: 343, desktop: '100%' },
            },
          },
        }}
        open={open}
        onClose={() => {
          if (!isMobile) {
            setOpen(false);
          }
        }}
        onOpen={() => {
          if (!isMobile) {
            setOpen(true);
          }
        }}
        disableFocusListener={isMobile}
        disableHoverListener={isMobile}
        disableTouchListener={isMobile}
        title={
          <Box sx={{ position: 'relative', px: 1, py: '6px' }}>
            <IconButton
              onClick={closeTooltip}
              sx={{
                display: { desktop: 'none' },
                position: 'absolute',
                right: 8,
                top: 6,
              }}
            >
              <CloseIcon sx={{ fontSize: 18 }} />
            </IconButton>
            <Typography
              sx={{
                color: 'neutral.light',
                fontSize: 12,
                width: { mobile: 290, desktop: '100%' },
              }}
            >
              {addedSpaces}
            </Typography>
          </Box>
        }
      >
        <Typography
          sx={{
            color: 'neutral.body',
            fontSize: 14,
            fontWeight: 600,
            maxWidth: { mobile: 290, desktop: 400 },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {addedSpaces}
        </Typography>
      </Tooltip>
      <Button
        variant="outlined"
        onClick={openTooltip}
        sx={{
          display: { desktop: 'none' },
          height: 30,
          minWidth: 36,
          width: 36,
        }}
      >
        <VisibilityIcon sx={{ color: 'primary.main', fontSize: 18 }} />
      </Button>
    </Stack>
  );
};

export default RoomName;
