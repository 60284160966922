import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';
import { TotalByDate } from '../types';

interface GetTotalViewsByDateProps {
  itemId: string;
  dateRange: DateRange;
  selectedState: string;
}

const getTotalViewsByDate = async ({
  itemId,
  dateRange,
  selectedState,
}: GetTotalViewsByDateProps): Promise<TotalByDate[]> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  const baseURL = `/v1/analytics/vod/${itemId}`;
  const urlWithDates = `${dateFilter}&region=${selectedState}`;
  const { data: TotalByDate } = await axios.get(
    `${baseURL}/total-views-by-date${urlWithDates}`,
  );
  return TotalByDate as TotalByDate[];
};

export default getTotalViewsByDate;
