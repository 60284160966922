import { axios } from 'helpers/axios/axios';
import { DevicesCount } from '../../../interfaces/device';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

export interface SpeedProps {
  speed: number;
  total: number;
}

export interface MostUsedResolutions {
  resolutions: {
    240: number;
    360: number;
    480: number;
    720: number;
    1080: number;
  };
}

export interface DevicesResponse {
  devices: DevicesCount;
}

export interface AverageWatchedTimeResponse {
  durationInSeconds: number;
}

export interface AverageWatchedTimePercentageResponse {
  durationInPercentage: number;
}

export interface ContentInfo {
  totalSpeeds: SpeedProps[];
  mostUsedResolutions: MostUsedResolutions;
  mostUsedDevices: DevicesResponse;
  averageWatchedTimePercentage: AverageWatchedTimePercentageResponse;
  averageWatchedTime: AverageWatchedTimeResponse;
}

export interface GetStatisticsInfoProps {
  contentId: string;
  dateRange: DateRange;
  selectedState: string;
}

const getVodStatisticsInfo = async ({
  contentId,
  dateRange,
  selectedState,
}: GetStatisticsInfoProps): Promise<ContentInfo> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  const baseURL = `/v1/analytics/vod/${contentId}`;
  const urlWithDates = `${dateFilter}&region=${selectedState}`;
  const { data: totalSpeeds } = await axios.get(
    `${baseURL}/total-speeds${urlWithDates}`,
  );
  const { data: mostUsedResolutions } = await axios.get(
    `${baseURL}/most-used-resolutions${urlWithDates}`,
  );
  const { data: mostUsedDevices } = await axios.get(
    `${baseURL}/most-used-devices${urlWithDates}`,
  );
  const { data: averageWatchedTimePercentage } = await axios.get(
    `${baseURL}/average-watched-time-percentage${urlWithDates}`,
  );
  const { data: averageWatchedTime } = await axios.get(
    `${baseURL}/average-watched-time${urlWithDates}`,
  );
  return {
    totalSpeeds,
    mostUsedResolutions,
    mostUsedDevices,
    averageWatchedTimePercentage,
    averageWatchedTime,
  };
};

export default getVodStatisticsInfo;
