import * as am5 from '@amcharts/amcharts5';
import brazilLow from '@amcharts/amcharts5-geodata/brazilLow';
import * as am5map from '@amcharts/amcharts5/map';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useContext, useLayoutEffect } from 'react';
import MapChartSkeleton from '../../../components/MapChartSkeleton';
import Context from '../../../Context';
import { Region } from '../../../types';
import getWordsInterestByState from '../services/getWordsInterestByState';
import getWordsInterestByStateAndVod from '../services/getWordsInterestByStateAndVod';

const MapChart = () => {
  const { dateRange, itemId, isDir } = useContext(Context);
  const { isLoading, data } = useQuery([dateRange, itemId], async () => {
    const request = isDir
      ? getWordsInterestByState(itemId, dateRange)
      : getWordsInterestByStateAndVod(itemId, dateRange);
    return await request;
  });
  const { palette } = useTheme();
  const startColor = palette.primary[100];
  const defaultColor = palette.primary[50];
  const endColor = palette.primary.dark;
  const tooltipColor = palette.common.white;
  useLayoutEffect(() => {
    let root: am5.Root;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (data) {
      let values: Region[] = [];
      Object.keys(data.total).forEach((key: string) => {
        values = [...values, { id: `BR-${key}`, value: data.total[key] }];
      });
      root = am5.Root.new('chartdiv');
      if (root._logo != null) {
        root._logo.dispose();
      }
      root.setThemes([am5themesAnimated.new(root)]);
      const chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: 'rotateX',
          panY: 'none',
          layout: root.horizontalLayout,
        }),
      );
      const polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: brazilLow,
          valueField: 'value',
          calculateAggregates: true,
          fill: am5.color(defaultColor),
          stroke: am5.color(0xffffff),
        }),
      );
      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
      });
      tooltip.get('background')?.setAll({
        fill: am5.color(tooltipColor),
        fillOpacity: 1.0,
      });
      polygonSeries.set('tooltip', tooltip);
      polygonSeries.mapPolygons.template.setAll({
        tooltipHTML: `<strong>{name}</strong>
      <br />
      <tr>
      <th align="left">Visualizações:</th>
      <td>{value}</td>
      </tr>`,
      });
      polygonSeries.set('heatRules', [
        {
          target: polygonSeries.mapPolygons.template,
          dataField: 'value',
          min: am5.color(startColor),
          max: am5.color(endColor),
          key: 'fill',
        },
      ]);
      const heatLegend = chart.children.push(
        am5.HeatLegend.new(root, {
          orientation: 'vertical',
          startColor: am5.color(startColor),
          endColor: am5.color(endColor),
          stepCount: 5,
        }),
      );
      polygonSeries.mapPolygons.template.events.on(
        'pointerover',
        (event: any) =>
          heatLegend.showValue(event.target.dataItem.get('value')),
      );
      polygonSeries.data.setAll([...values]);
      polygonSeries.mapPolygons.template.states.create('hover', {
        stroke: am5.color(0x000000),
        strokeOpacity: 0.5,
        strokeWidth: 1,
      });
      heatLegend.startLabel.setAll({
        fontSize: 12,
        fill: heatLegend.get('startColor'),
      });
      heatLegend.endLabel.setAll({
        fontSize: 12,
        fill: heatLegend.get('endColor'),
      });
      polygonSeries.events.on('datavalidated', function () {
        heatLegend.set('startValue', polygonSeries.getPrivate('valueLow'));
        heatLegend.set('endValue', polygonSeries.getPrivate('valueHigh'));
      });
    }
    return () => root?.dispose();
  }, [data]);
  if (isLoading) return <MapChartSkeleton />;
  return (
    <Card>
      <CardContent>
        <Typography variant="body1" sx={{ color: 'neutral.body' }}>
          Visualizações por estado
        </Typography>
        <Box
          id="chartdiv"
          sx={{ bgcolor: 'neutral.light', height: 369, mt: 2, width: '100%' }}
        />
      </CardContent>
    </Card>
  );
};

export default MapChart;
