const authorizationStrategy = (
  permissions: string[],
  requirement: string | string[],
) => {
  const dontNeedAuthorization = requirement == null;
  if (dontNeedAuthorization) {
    return true;
  }
  const requirementType = typeof requirement;
  const isSingleRole = requirementType === 'string';
  if (isSingleRole) {
    const hasPermission = permissions.includes(requirement as string);
    return hasPermission;
  }
  const isMultipleRoles = Array.isArray(requirement);
  if (isMultipleRoles) {
    const hasPermission = requirement.some((req) => permissions.includes(req));
    return hasPermission;
  }
  throw new Error('Cannot determine role requirements');
};

export default authorizationStrategy;
