import { useAuthorize } from '@tshio/react-router-permissions';
import { Navigate } from 'react-router-dom';

interface CustomAuthorizedRouteProps {
  requires: string | string[];
  children: JSX.Element;
}

const CustomAuthorizedRoute = ({
  requires,
  children,
}: CustomAuthorizedRouteProps) => {
  const isAuthorized: boolean = useAuthorize(requires);
  return isAuthorized ? children : <Navigate to="/403" />;
};

export default CustomAuthorizedRoute;
