import { Divider, Grid, Skeleton } from '@mui/material';

const ListItemSkeleton = () => (
  <>
    <Grid container sx={{ height: 48 }}>
      <Grid
        alignItems="center"
        container
        direction="row"
        item
        gap={5}
        mobile={6}
      >
        <Skeleton variant="text" sx={{ width: '80%' }} />
      </Grid>
      <Grid container item mobile>
        <Skeleton variant="text" sx={{ width: '20%' }} />
      </Grid>
    </Grid>
    <Divider sx={{ my: 2 }} />
  </>
);

export default ListItemSkeleton;
