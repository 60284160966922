import { BarChartRounded as ChartIcon } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';

interface Props {
  callback: () => void;
}

const EmptyChart = ({ callback }: Props) => {
  useEffect(() => {
    callback();
  }, []);
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <ChartIcon sx={{ color: 'neutral.muted' }} />
      <Typography sx={{ color: 'neutral.body', fontSize: 20, fontWeight: 600 }}>
        Sem informações
      </Typography>
      <Typography
        sx={{
          color: 'neutral.50',
          fontSize: 14,
          maxWidth: 352,
          textAlign: 'center',
        }}
      >
        Não há informações contabilizadas referente ao mês selecionado.
      </Typography>
    </Stack>
  );
};

export default EmptyChart;
