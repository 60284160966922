import { FiberManualRecord as DotIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  Chip,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { items } from '../items';
import AccordionSummaryItem from './AccordionSummaryItem';

const ItemsSkeletons = () => (
  <>
    {items.map((item, index) => (
      <Accordion
        key={`${item.title}-${index}`}
        disableGutters
        sx={{
          '::before': { bgcolor: 'common.white' },
        }}
      >
        {item?.subItems == null ? (
          <Link style={{ textDecoration: 'none', width: '100%' }}>
            <AccordionSummaryItem item={item} isBeta={item.isBeta} />
          </Link>
        ) : (
          <AccordionSummaryItem item={item} />
        )}
        {item.subItems != null && (
          <AccordionDetails
            sx={{
              p: 0,
            }}
          >
            {item?.subItems?.map((subItem) => (
              <Link key={subItem.href} href={subItem.href} style={{ textDecoration: 'none' }}>
                <List
                  key={subItem.title}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    height: 45,
                    pl: 7,
                    '&:hover': {
                      bgcolor: 'neutral.light',
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      p: 0,
                      '&:hover': {
                        bgcolor: 'transparent',
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                      <DotIcon
                        sx={{
                          color: 'neutral.25',
                          fontSize: 4,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={subItem.title}
                      primaryTypographyProps={{
                        sx: {
                          color: 'neutral.body',
                          fontSize: 14,
                          '&:hover': { color: 'primary.main' },
                        },
                      }}
                    />
                    {Boolean(subItem.isBeta ?? false) && (
                      <Chip label="BETA" color="info" sx={{ mr: 5 }} />
                    )}
                  </ListItemButton>
                </List>
              </Link>
            ))}
          </AccordionDetails>
        )}
      </Accordion>
    ))}
  </>
);

export default ItemsSkeletons;
