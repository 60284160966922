import { CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Context from '../../../Context';
import getRetentionByMinutes from '../services/getRetentionByMinutes';
import LineChart from './LineChart';

const RentemptionByMinute = () => {
  const { allStatesValue, itemId, dateRange, selectedState } =
    useContext(Context);
  const newSelectedState =
    selectedState === allStatesValue ? '' : selectedState;
  const { isLoading, data } = useQuery(
    [
      'RetentionByMinutes',
      itemId,
      dateRange.startDate.toISOString(),
      dateRange.endDate.toISOString(),
      selectedState,
    ],
    async () => {
      const request = getRetentionByMinutes({
        itemId,
        dateRange,
        selectedState: newSelectedState,
      });
      return await request;
    },
  );
  const isLoadingData = isLoading || data === undefined;
  if (isLoadingData) return <CircularProgress />;
  const sanitizedData = data?.map(({ currentTime, retention }) => ({
    date: `${currentTime}`,
    total: retention,
  }));
  return (
    <Stack
      justifyContent="center"
      sx={{ height: '100%', ml: -5, pl: '-34px', width: '100%' }}
    >
      <LineChart
        tooltipTitle="Retenção"
        yAxisIsPercents
        hasZoom
        data={sanitizedData}
        itemId={itemId}
        xAxisIsinSeconds
      />
    </Stack>
  );
};

export default RentemptionByMinute;
