import { axios } from 'helpers/axios/axios';
import { Vod } from '../../../interfaces/vod';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

const getMostViewedVods = async (
  itemId: string,
  dateRange: DateRange,
  selectedState: string,
): Promise<Vod[]> => {
  const baseURL = `/v1/analytics/directory`;
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  const urlWithDates = `${dateFilter}&region=${selectedState}`;
  return await axios
    .get(`${baseURL}/most-viewed-videos${urlWithDates}&directory=${itemId}`)
    .then((data) => data.data);
};

export default getMostViewedVods;
