import { Lock as LockIcon } from '@mui/icons-material';
import { Card, CardContent, Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  title: string;
  icon: ReactNode;
  value?: string | number;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const MetricsCard = ({ title, icon, value, disabled = false, sx }: Props) => (
  <Card
    sx={{
      cursor: disabled ? 'not-allowed' : 'default',
      height: 107,
      width: 'calc(50% - 16px)',
      ...sx,
    }}
  >
    <CardContent>
      <Stack alignItems="center" direction="row" gap={1} sx={{ mb: 2 }}>
        {disabled && <LockIcon sx={{ color: 'neutral.muted', fontSize: 16 }} />}{' '}
        <Typography
          variant="body2"
          sx={{
            color: disabled ? 'neutral.muted' : 'neutral.50',
          }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        gap={3}
        sx={{ color: disabled ? 'neutral.muted' : 'primary.main' }}
      >
        {icon}
        <Typography
          variant="h6"
          sx={{
            color: disabled ? 'neutral.muted' : 'neutral.body',
            fontWeight: 600,
          }}
        >
          {disabled ? '-' : value}
        </Typography>
      </Stack>
    </CardContent>
  </Card>
);

export default MetricsCard;
