import { RemoveRedEye as VisionIcon } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { Vod } from '../../../interfaces/vod';
import MostViewedVodsContext from './MostViewedVodsContext';

interface Props {
  vod: Vod;
  index: number;
}

const MostViewedVodsItem = ({ vod, index }: Props) => {
  const { changeSelectedVodId, selectedVodId } = useContext(
    MostViewedVodsContext,
  );
  const isSelected = selectedVodId === vod.videoId;
  return (
    <Stack
      alignItems="center"
      direction="row"
      sx={{ mb: 4, ':nth-child(5)': { mb: 0 } }}
    >
      <Button
        sx={{
          bgcolor: isSelected ? 'primary.dark' : 'primary.main',
          height: 32,
          minWidth: 0,
          mr: 3,
          p: 0,
          width: 32,
        }}
        onClick={() => changeSelectedVodId(vod.videoId)}
      >
        {index}
      </Button>
      <Avatar
        variant="square"
        src={vod.thumbnail}
        sx={{ borderRadius: 1, height: 48, mr: 5, width: 48 }}
      />
      <Box>
        <Typography
          sx={{
            color: 'neutral.body',
            fontSize: 14,
            maxWidth: '16vw',
            mb: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {vod.title || 'Sem título'}
        </Typography>
        <Chip
          icon={<VisionIcon />}
          label={vod.views.toLocaleString('pt-BR')}
          sx={{ bgcolor: 'neutral.light', width: 'auto' }}
        />
      </Box>
    </Stack>
  );
};

export default MostViewedVodsItem;
