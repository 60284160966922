import {
  Box,
  Card,
  CardContent,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { TotalActivatedSearch } from '../services/getPercentageActivatedSearch';

interface SubtitleActivationPercentageCardProps {
  data?: TotalActivatedSearch;
  sx?: SxProps<Theme>;
}

const CustomCircularProgress = (
  { data }: SubtitleActivationPercentageCardProps,
  props?: CircularProgressProps,
) => {
  const activatedSearchPercentual = !data?.percentage ? 0 : data.percentage;
  const formattedPercentual = Number.isInteger(activatedSearchPercentual)
    ? activatedSearchPercentual
    : activatedSearchPercentual.toFixed(2);
  return (
    <Box sx={{ height: 75, position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        size={75}
        thickness={4}
        value={100}
        sx={{ color: 'neutral.25' }}
        {...props}
      />
      <CircularProgress
        variant="determinate"
        value={data?.percentage}
        size={75}
        thickness={4}
        sx={{
          animationDuration: '550ms',
          left: 0,
          position: 'absolute',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        {...props}
      />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <Typography sx={{ color: 'neutral.50', fontWeight: 600 }}>
          {formattedPercentual}%
        </Typography>
      </Stack>
    </Box>
  );
};

const SubtitleActivationPercentageCard = ({
  data,
  sx,
}: SubtitleActivationPercentageCardProps) => (
  <Card
    sx={{
      height: 107,
      width: 'calc(50% - 16px)',
      ...sx,
    }}
  >
    <CardContent>
      <Stack alignItems="center" direction="row" gap={5}>
        <CustomCircularProgress data={data} />
        <Typography variant="body2" sx={{ color: 'neutral.50', width: 145 }}>
          Espectadores que ativaram a lupa
        </Typography>
      </Stack>
    </CardContent>
  </Card>
);

export default SubtitleActivationPercentageCard;
