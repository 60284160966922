import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Participant, Session } from '../types';
import addNameOnParticipantList from '../utils/addNameOnParticipantList';

const cellStyles = {
  borderBottom: 'none',
  borderRight: '1px solid #D1D3E0',
  borderLeft: 'none',
  color: 'neutral.body',
  fontSize: 12,
  pl: { mobile: 4, desktop: 5 },
  pr: 1,
  py: 2,
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {
    pl: { mobile: 4, desktop: 5 },
  },
  '&:last-child': {
    borderRight: 'none',
  },
};

const labels = ['Participantes', 'Participação'];

interface Props {
  participants: Participant[];
  sessionId: Session['roomSessionId'];
}

const ParticipantTable = ({ participants, sessionId }: Props) => {
  const participantsWithName = addNameOnParticipantList(participants);
  return (
    <Table>
      <TableHead>
        <TableRow
          sx={{
            '&:first-of-type': {
              borderTop: 'none',
            },
          }}
        >
          {labels.map((label) => (
            <TableCell
              key={label}
              sx={{
                color: 'neutral.muted',
                border: '1px solid #D1D3E0',
                borderLeft: 'none',
                borderTop: 'none',
                fontSize: 12,
                pr: 1,
                pb: 2,
                pl: { mobile: 4, desktop: 5 },
                pt: 4,
                textTransform: 'uppercase',
                '&:first-of-type': {
                  pl: { mobile: 4, desktop: 5 },
                },
                '&:last-child': {
                  borderRight: 'none',
                },
                '&:before': {
                  display: 'none',
                },
              }}
            >
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {participantsWithName.map((participant) => (
          <TableRow
            key={participant.participantID}
            sx={{
              border: 0,
              borderBottom: '1px solid #D1D3E0',
              '&:last-child': {
                borderBottom: 'none',
              },
            }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{ ...cellStyles, width: { desktop: 846 } }}
            >
              {participant.name}
            </TableCell>
            <TableCell sx={cellStyles}>{participant.duration}min</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ParticipantTable;
