import { Box, Stack, Typography } from '@mui/material';
import {
  Folder as FolderIcon,
  KeyboardArrowDown as ArrowDownIcon,
} from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { Directory } from 'types/Directory';
import { Folder } from 'types/Folder';
import makeFolder from 'utils/makeFolder';

interface DirectoryItemProps {
  id: string;
  root: Directory;
  selectedFolderId: Folder['id'];
  onChangeSelectedFolderId: (id: string, normalizedFullPath: string) => void;
}

const DirectoryItem = ({
  id,
  root,
  selectedFolderId,
  onChangeSelectedFolderId,
}: DirectoryItemProps) => {
  const file = makeFolder(root, id);
  const isRootFolder = !!file.rootFolder;
  const [expanded, setExpanded] = useState(isRootFolder);
  const hasChildrens = file.childrenCount > 0;
  const isSelected = selectedFolderId === id;
  const memoizedChildrenList = useMemo(
    () =>
      file.childrens.map((children) => (
        <DirectoryItem
          key={children.id}
          id={children.id}
          root={root}
          selectedFolderId={selectedFolderId}
          onChangeSelectedFolderId={onChangeSelectedFolderId}
        />
      )),
    [file.childrens],
  );
  const handleExpandClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setExpanded((prevState) => !prevState);
  };
  return (
    <Box sx={{ pl: isRootFolder ? 0 : 5 }}>
      {!isRootFolder && (
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          sx={{
            bgcolor: isSelected ? 'primary.50' : 'none',
            borderRadius: 1,
            cursor: 'pointer',
            height: 40,
            ':hover': { bgcolor: 'neutral.light' },
          }}
          onClick={() => onChangeSelectedFolderId(id, file.normalizedFullPath)}
        >
          <ArrowDownIcon
            sx={{
              color: 'neutral.muted',
              fontSize: 24,
              opacity: hasChildrens ? 1 : 0,
              transform: expanded ? 'rotate(0)' : 'rotate(-90deg)',
              transition: '.3s',
            }}
            onClick={handleExpandClick}
          />
          <FolderIcon sx={{ color: 'neutral.muted', fontSize: 24 }} />
          <Typography sx={{ color: 'neutral.body', fontSize: 14 }}>
            {file.name}
          </Typography>
        </Stack>
      )}
      {expanded && memoizedChildrenList}
    </Box>
  );
};

export default DirectoryItem;
