import { Grid } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import Context from '../../../Context';
import MostViewedVodsContext from './MostViewedVodsContext';
import MostViewedVodsInfo from './MostViewedVodsInfo';
import MostViewedVodsList from './MostViewedVodsList';

const MostViewedVods = () => {
  const { allStatesValue, itemId, dateRange, selectedState } =
    useContext(Context);
  const [selectedVodId, setSelectedVodId] = useState<string>('');
  const changeSelectedVodId = useCallback(
    (id: string) => setSelectedVodId(id),
    [],
  );
  return (
    <MostViewedVodsContext.Provider
      value={{ selectedVodId, changeSelectedVodId }}
    >
      <Grid container gap={4} sx={{ mt: 3 }}>
        <Grid item desktop={7}>
          <MostViewedVodsList
            allStatesValue={allStatesValue}
            itemId={itemId}
            dateRange={dateRange}
            selectedState={selectedState}
          />
        </Grid>
        <Grid item desktop>
          <MostViewedVodsInfo vodId={selectedVodId} dateRange={dateRange} />
        </Grid>
      </Grid>
    </MostViewedVodsContext.Provider>
  );
};

export default MostViewedVods;
