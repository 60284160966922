import { KeyboardArrowDownRounded as ArrowDownIcon } from '@mui/icons-material';
import { Popover, Stack, Typography } from '@mui/material';
import DatePicker from 'components/DatePicker';
import { defaultRanges } from 'components/DatePicker/defaults';
import { useMemo, useState } from 'react';
import formatDate from 'utils/formatDate';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface Props {
  dateRange: DateRange;
  changeDateRange: (dates: DateRange) => void;
}

const DateRangePickerComponent = ({ dateRange, changeDateRange }: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseModal = () => setAnchorEl(null);
  const { startDate, endDate } = dateRange;
  const formatedDate = useMemo(
    () =>
      `${formatDate(startDate, 'dd/MM/yy')} - ${formatDate(
        endDate,
        'dd/MM/yy',
      )}`,
    [dateRange],
  );
  const handleOpenModal = (event: any) => setAnchorEl(event.currentTarget);
  const open = Boolean(anchorEl);
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <DatePicker
          open
          definedRanges={defaultRanges}
          initialDateRange={dateRange}
          onChange={(range) => changeDateRange(range as DateRange)}
        />
      </Popover>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        gap={3}
        sx={{
          bgcolor: 'neutral.light',
          borderRadius: 1,
          cursor: 'pointer',
          height: 37,
          minWidth: 166,
          px: 4,
          py: 2,
        }}
        onClick={handleOpenModal}
      >
        <Typography variant="body3" sx={{ color: 'neutral.dark' }}>
          {formatedDate}
        </Typography>
        <ArrowDownIcon />
      </Stack>
    </>
  );
};

export default DateRangePickerComponent;
