import { Route, Routes } from 'react-router-dom';
import routeList from './routes';
import VoDTemplate from './VoDTemplate';

const VoDModule = () => (
  <Routes>
    <Route element={<VoDTemplate routes={routeList} />}>
      {routeList.map(({ element: Element, ...route }) => (
        <Route key={route.path} {...route} element={<Element />} />
      ))}
    </Route>
  </Routes>
);

export default VoDModule;
