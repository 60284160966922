import { Button } from '@mui/material';

interface Props {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

const MetricButton = ({ label, isSelected, onClick }: Props) => (
  <Button
    variant="text"
    onClick={onClick}
    sx={{
      color: isSelected ? 'primary.main' : 'neutral.50',
      fontSize: 14,
      fontWeight: 600,
      mr: 5,
    }}
  >
    {label}
  </Button>
);

export default MetricButton;
