import { RemoveRedEye as VisionIcon } from '@mui/icons-material';
import { Box, Chip, Skeleton, Stack } from '@mui/material';

const MostViewedVodsItemSkeletons = () => (
  <Stack
    alignItems="center"
    direction="row"
    sx={{ mb: 4, ':nth-child(5)': { mb: 0 } }}
  >
    <Skeleton variant="rounded" height={32} width={32} sx={{ mr: 3 }} />
    <Skeleton variant="circular" height={48} width={48} sx={{ mr: 5 }} />
    <Box>
      <Skeleton
        variant="text"
        width={200}
        sx={{ fontSize: 14, mb: 1, mr: 3 }}
      />
      <Chip
        icon={<VisionIcon />}
        label={
          <Skeleton
            variant="text"
            width={32}
            sx={{ fontSize: 14, mb: 1, mr: 3 }}
          />
        }
        sx={{ bgcolor: 'neutral.light', width: 'auto' }}
      />
    </Box>
  </Stack>
);

export default MostViewedVodsItemSkeletons;
