import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { formatMinutesFromHours } from 'utils/time';
import { Session } from '../types';
import ParticipantSection from './ParticipantSection';

interface Props {
  index: number;
  session: Session;
}

const SessionItem = ({ index, session }: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <AccordionDetails sx={{ p: 0 }}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{
          borderTop: 1,
          borderColor: 'neutral.25',
          borderRadius: 0,
          '&::before': { height: 0 },
          '&:last-of-type': { borderRadius: 0 },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            height: { desktop: 56 },
            px: { mobile: 4, desktop: 5 },
            '&.Mui-expanded': { minHeight: { desktop: 56 } },
            '.MuiAccordionSummary-content': {
              my: 3,
              '&.Mui-expanded': { my: 3 },
            },
          }}
        >
          <Stack
            alignItems={{ desktop: 'center' }}
            direction={{ desktop: 'row' }}
            gap={4}
          >
            <Typography
              sx={{ color: 'neutral.body', fontSize: 14, fontWeight: 600 }}
            >
              Sessão #{index + 1}
            </Typography>
            <Stack
              alignItems={{ desktop: 'center' }}
              direction={{ desktop: 'row' }}
              gap={{ desktop: 2 }}
            >
              <Typography sx={{ color: 'neutral.body', fontSize: 12 }}>
                Data:{' '}
                <Typography
                  component="span"
                  display="inline"
                  sx={{
                    color: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 600,
                  }}
                >
                  {format(new Date(session.startedAt), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              </Typography>
              <Typography sx={{ color: 'neutral.body', fontSize: 12 }}>
                Duração:{' '}
                <Typography
                  component="span"
                  display="inline"
                  sx={{
                    color: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 600,
                  }}
                >
                  {formatMinutesFromHours(session.totalStreamerMinutes)}
                </Typography>
              </Typography>
              <Typography sx={{ color: 'neutral.body', fontSize: 12 }}>
                Participantes:{' '}
                <Typography
                  component="span"
                  display="inline"
                  sx={{
                    color: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 600,
                  }}
                >
                  {session.totalUniqueParticipants}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <ParticipantSection
          expanded={expanded}
          shouldShowParticipantTable={!!session.endedAt}
          sessionId={session.roomSessionId}
        />
      </Accordion>
    </AccordionDetails>
  );
};

export default SessionItem;
