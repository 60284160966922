import { Components, Theme } from '@mui/material';

const makeMuiTableRow = (theme: Theme): Components['MuiTableRow'] => ({
  styleOverrides: {
    root: {
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.info.light}`,
      },
    },
  },
});

export default makeMuiTableRow;
