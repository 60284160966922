import {
  Button,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Word } from '../../../interfaces/word';

interface WordsInterestItemProps {
  index: number;
  percentage: number;
  word: Word;
}

const WordsInterestItem = ({
  index,
  percentage,
  word,
}: WordsInterestItemProps) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ mb: 6 }}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Button
          sx={{
            bgcolor: 'primary.main',
            height: 32,
            minWidth: 0,
            mr: 3,
            p: 0,
            width: 32,
            cursor: 'initial',
          }}
        >
          {index}
        </Button>
        <Typography
          sx={{
            color: 'neutral.body',
            fontSize: 14,
            maxWidth: '16vw',
            mb: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {word.term}
        </Typography>
      </Stack>
      <Tooltip title={`Total de buscas: ${word.total}`}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ borderLeft: 2, borderColor: 'divider', pl: 2 }}
        >
          <Typography
            sx={{
              color: 'neutral.50',
              fontFamily: 'Poppins',
              fontSize: 12,
              fontStyle: 'normal',
              fontWeight: 400,
              mr: 2,
            }}
          >
            {percentage.toFixed(2)}%
          </Typography>
          <LinearProgress
            variant="determinate"
            value={percentage}
            sx={{
              height: 10,
              minWidth: 126,
              ml: 2,
            }}
          />
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default WordsInterestItem;
