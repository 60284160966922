import { Room, Session } from '../types';

const getUniqueRoomsName = (roomsName: string[]) =>
  Array.from(new Set(roomsName));

const getRoomsName = (sessions: Session[]) =>
  sessions.map(({ roomName }) => roomName);

const sortByStartDate = (list: any[]) =>
  list.sort(
    (firstItem, secondItem) =>
      new Date(firstItem.startedAt).getTime() -
      new Date(secondItem.startedAt).getTime(),
  );

const getSessionsByRoomName = (
  sessions: Session[],
  roomName: string,
): Session[] => {
  const disorderlySessions = sessions.filter(
    (session) => session.roomName === roomName,
  );
  return sortByStartDate(disorderlySessions).reverse();
};

const countTotal = (array: number[]): number =>
  array.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0,
  );

const countTotalMinutesTheRoom = (sessions: Session[]) => {
  const totalMinutesInSessions = sessions.map(
    ({ totalStreamerMinutes }) => totalStreamerMinutes,
  );
  return countTotal(totalMinutesInSessions);
};

const countTotalParticipantsTheRoom = (sessions: Session[]) => {
  const totalParticipantsInSessions = sessions.map(
    ({ totalUniqueParticipants }) => totalUniqueParticipants,
  );
  return countTotal(totalParticipantsInSessions);
};

const getLiveNameByRoomName = (
  roomName: Session['roomName'],
  sessions: Session[],
): string =>
  sessions.find((currentSession) => currentSession.roomName === roomName)
    ?.liveName ?? '';

export const sessionGroupedByRoom = (sessions: Session[]): Room[] => {
  if (!sessions) {
    return [];
  }
  const roomsName = getUniqueRoomsName(getRoomsName(sessions));
  return roomsName.map((roomName) => {
    const roomSessions = getSessionsByRoomName(sessions, roomName);
    return {
      roomName,
      liveName: getLiveNameByRoomName(roomName, sessions),
      totalMinutes: countTotalMinutesTheRoom(roomSessions),
      totalParticipants: countTotalParticipantsTheRoom(roomSessions),
      totalSessions: roomSessions.length,
      sessions: roomSessions,
    };
  });
};
