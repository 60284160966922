import * as am5 from '@amcharts/amcharts5';
import am5locales_pt from '@amcharts/amcharts5/locales/pt_BR';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useLayoutEffect } from 'react';
import { Devices } from '../../../interfaces/device';

interface Props {
  devices: Record<Devices, number>;
}

const MostUsedDevicesChart = ({ devices }: Props) => {
  const checkIfHasDataHandle = (devices: Record<Devices, number>): boolean => {
    return Object.values(devices).every((device) => device === 0);
  };
  const hasNoData = checkIfHasDataHandle(devices);
  const { palette } = useTheme();
  useLayoutEffect(() => {
    const root = am5.Root.new('chartdiv');
    root.setThemes([am5themesAnimated.new(root)]);
    root.locale = am5locales_pt;
    root.numberFormatter.setAll({ numberFormat: '#,###.00' });
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
      }),
    );
    const yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 30 });
    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'device',
        renderer: yRenderer,
      }),
    );
    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis,
        yAxis,
        valueXField: 'value',
        sequencedInterpolation: true,
        categoryYField: 'device',
      }),
    );
    const columnTemplate = series.columns.template;
    columnTemplate.setAll({
      templateField: 'sliceSettings',
      cursorOverStyle: 'pointer',
      cornerRadiusBR: 20,
      cornerRadiusTR: 20,
      height: 17,
      strokeOpacity: 0,
      tooltipHTML: '<span>{value}</span>',
    });
    const data = hasNoData
      ? [
          {
            device: 'Mobile',
            value: 1000,
            sliceSettings: { fill: am5.color(palette.neutral[25]) },
          },
          {
            device: 'Tablet',
            value: 1000,
            sliceSettings: { fill: am5.color(palette.neutral[25]) },
          },
          {
            device: 'Desktop',
            value: 1000,
            sliceSettings: { fill: am5.color(palette.neutral[25]) },
          },
        ]
      : [
          {
            device: 'Mobile',
            value: devices.mobile,
            sliceSettings: { fill: am5.color(palette.primary.main) },
          },
          {
            device: 'Tablet',
            value: devices.tablet,
            sliceSettings: { fill: am5.color(palette.success.main) },
          },
          {
            device: 'Desktop',
            value: devices.desktop,
            sliceSettings: { fill: am5.color(palette.info.main) },
          },
        ];
    yAxis.data.setAll(data);
    series.data.setAll(data);
    if (root._logo != null) {
      root._logo.dispose();
    }
    void series.appear(1000);
    void chart.appear(1000, 100);
    return () => root?.dispose();
  }, []);
  return (
    <Stack>
      <Typography sx={{ color: 'neutral.dark', fontWeight: 600, mb: 7, ml: 6 }}>
        Dispositivos mais acessados
      </Typography>
      <Box id="chartdiv" sx={{ width: 391, minHeight: 180 }} />
    </Stack>
  );
};

export default MostUsedDevicesChart;
