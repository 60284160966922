import StudioModule from 'modules/Studio/StudioModule';
import VoDModule from 'modules/VoD/VoDModule';

interface RouteProps {
  path: string;
  element: JSX.Element;
  requires: string | string[];
}

export const routes: RouteProps[] = [
  {
    path: '/vod/*',
    element: <VoDModule />,
    requires: [
      'showStreaming',
      'showVOD',
      'showHistory',
      'showStreamingAnalytics',
    ],
  },
  {
    path: '/studio/*',
    element: <StudioModule />,
    requires: [
      'showStreaming',
      'showVOD',
      'showHistory',
      'showStreamingAnalytics',
    ],
  },
];
