import { CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Context from '../../../Context';
import getTotalWatchedTime from '../services/getTotalWatchedTime';
import getTotalWatchedTimeByFolder from '../services/getTotalWatchedTimeByFolder';
import LineChart from './LineChart';

const TotalWatchedTimeChart = () => {
  const { allStatesValue, itemId, isDir, dateRange, selectedState } =
    useContext(Context);
  const newSelectedState =
    selectedState === allStatesValue ? '' : selectedState;
  const { isLoading, data } = useQuery(
    [
      'TotalWatchedTime',
      itemId,
      dateRange.startDate.toISOString(),
      dateRange.endDate.toISOString(),
      selectedState,
    ],
    async () => {
      const request = isDir ? getTotalWatchedTimeByFolder : getTotalWatchedTime;
      const props = { itemId, dateRange, selectedState: newSelectedState };
      return await request({ ...props });
    },
  );
  const isLoadingData = isLoading || data === undefined;
  if (isLoadingData) return <CircularProgress />;
  return (
    <Stack>
      <LineChart data={data} yAxisIsInSeconds={true} />
    </Stack>
  );
};

export default TotalWatchedTimeChart;
