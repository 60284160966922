import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

interface TotalWordsInterestByState {
  total: Record<string, number>;
}

const getWordsInterestByStateAndVod = async (
  vodId: string,
  dateRange: DateRange,
): Promise<TotalWordsInterestByState> => {
  const queryStringFilters = formatBaseURLWithDateRange(dateRange);
  const baseURL = `/v1/analytics/vod/${vodId}`;
  return await axios
    .get(
      `${baseURL}/words-interest-by-region${queryStringFilters}&vodId=${vodId}`,
    )
    .then((data) => data.data);
};

export default getWordsInterestByStateAndVod;
