import { ArrowBackRounded as ArrowBackRoundedIcon } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import DateRangePickerComponent from 'components/DatePicker/DateRangePicker';
import { BRAZIL_STATES } from 'consts/geoOptions';
import { SUBDOMAIN } from 'consts/subdomain';
import { ReactNode, useContext } from 'react';
import Context from '../../Context';
import ChooseFolder from './components/ChooseFolder';

interface Props {
  title: string | ReactNode;
  isOnSkeleton?: boolean;
}

const Navbar = ({ title, isOnSkeleton }: Props) => {
  const {
    allStatesValue,
    isDir,
    dateRange,
    selectedState,
    setSelectedState,
    setDateRange,
  } = useContext(Context);
  const backToVodLibrary = () =>
    window.location.replace(`${SUBDOMAIN}/vod/dashboard`);
  const resetFilter = () => {
    setDateRange({ startDate: new Date(), endDate: new Date() });
    setSelectedState(allStatesValue);
  };
  return (
    <Card sx={{ height: 70, mb: 4 }}>
      <CardContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Stack alignItems="center" direction="row" gap={5}>
          <IconButton onClick={backToVodLibrary}>
            <ArrowBackRoundedIcon />
          </IconButton>
          <Typography
            variant="body1"
            sx={{
              color: 'neutral.body',
              fontWeight: 600,
              maxWidth: '45vw',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" gap={4}>
          <Typography
            variant="body1"
            sx={{ color: 'neutral.body', fontWeight: 600 }}
          >
            Filtros
          </Typography>
          {isOnSkeleton ? (
            <Select
              sx={{
                bgcolor: 'neutral.light',
                fieldset: { display: 'none' },
                fontSize: 'body3',
                height: 37,
                width: 180,
              }}
            />
          ) : (
            <DateRangePickerComponent
              dateRange={dateRange}
              changeDateRange={setDateRange}
            />
          )}
          <Select
            name="states"
            value={selectedState}
            onChange={(event: SelectChangeEvent) =>
              setSelectedState(event.target.value)
            }
            sx={{
              bgcolor: 'neutral.light',
              fieldset: { display: 'none' },
              fontSize: 'body3',
              height: 37,
              width: 180,
            }}
          >
            <MenuItem
              key={allStatesValue}
              value={allStatesValue}
              sx={{ fontSize: 'body3' }}
            >
              Todos
            </MenuItem>
            {BRAZIL_STATES.map((state: any) => (
              <MenuItem
                key={state.value}
                value={state.value}
                sx={{ fontSize: 'body3' }}
              >
                {state.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            sx={{ fontSize: 'body2', minWidth: 118 }}
            onClick={resetFilter}
          >
            Limpar filtros
          </Button>
          {isDir && <ChooseFolder />}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Navbar;
