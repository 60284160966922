import {
  addWeeks,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';
import { DefinedRange } from './types';

const getDefaultRanges = (date: Date): DefinedRange[] => [
  {
    label: 'Hoje',
    startDate: date,
    endDate: date,
  },
  {
    label: 'Essa semana',
    startDate: startOfWeek(date),
    endDate: endOfWeek(date),
  },
  {
    label: 'Última semana',
    startDate: startOfWeek(addWeeks(date, -1)),
    endDate: endOfWeek(addWeeks(date, -1)),
  },
  {
    label: 'Últimos 15 dias',
    startDate: sub(addWeeks(date, -2), { days: 1 }),
    endDate: date,
  },
  {
    label: 'Esse mês',
    startDate: startOfMonth(date),
    endDate: endOfMonth(date),
  },
  {
    label: 'Limpar filtro',
    startDate: date,
    endDate: date,
  },
];

export const defaultRanges = getDefaultRanges(new Date());
