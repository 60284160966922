import formatDate from 'utils/formatDate';
import { DateRange } from '../types';

const formatBaseURLWithDateRange = (
  dateRange: DateRange,
  format?: string,
): string => {
  const dateFormat = format ?? 'yyyy/MM/dd';
  const startDate = formatDate(new Date(dateRange.startDate), dateFormat);
  const endDate = formatDate(new Date(dateRange.endDate), dateFormat);
  const urlWithDates = `?startDate=${startDate}&endDate=${endDate}`;
  return urlWithDates;
};

export default formatBaseURLWithDateRange;
