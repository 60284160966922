import { Components, Theme } from '@mui/material';

const makeMuiIconButton = (theme: Theme): Components['MuiIconButton'] => ({
  defaultProps: { color: 'primary' },
  styleOverrides: {
    root: { padding: theme.spacing(2) },
    colorPrimary: { color: theme.palette.neutral.muted },
  },
});

export default makeMuiIconButton;
