import { Route as IRoute } from './interfaces/route';
import General from './pages/general';
import Popularity from './pages/popularity';
import Statistics from './pages/statistics';

const routes: IRoute[] = [
  {
    path: '/:id/general',
    element: General,
    blocked: false,
  },
  {
    path: '/:id/statistics',
    element: Statistics,
    blocked: false,
  },
  {
    path: '/:id/popularity',
    element: Popularity,
    blocked: false,
  },
];

export default routes;
