import { Divider, Grid, Skeleton } from '@mui/material';

const ListItemSkeleton = () => {
  return (
    <>
      <Grid container sx={{ height: 48 }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          gap={5}
          mobile={6}
        >
          <Skeleton variant="rounded" width={48} height={48} />
          <Skeleton variant="text" sx={{ width: '80%' }} />
        </Grid>
        <Grid container item mobile>
          <Skeleton variant="text" sx={{ width: '50%' }} />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default ListItemSkeleton;
