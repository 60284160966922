import { startOfMonth } from 'date-fns';
import { useState } from 'react';
import Container from './components/Container';
import Toolbar from './components/Toolbar';
import Context from './Context';

const Main = () => {
  const [searchName, setSearchName] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
  });
  return (
    <Context.Provider
      value={{ dateRange, searchName, setDateRange, setSearchName }}
    >
      <Toolbar />
      <Container />
    </Context.Provider>
  );
};

export default Main;
