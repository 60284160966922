import { List, ListItem, ListItemText } from '@mui/material';
import { DefinedRange, DateRange } from '../types';
import isSameRange from '../utils/isSameRange';

interface Props {
  ranges: DefinedRange[];
  selectedRange: DateRange;
  setRange: (range: DateRange) => void;
}

const DefinedRanges = ({ ranges, selectedRange, setRange }: Props) => {
  return (
    <List>
      {ranges.map((range, idx) => (
        <ListItem button key={idx} onClick={() => setRange(range)}>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
              sx: {
                fontWeight: isSameRange(range, selectedRange)
                  ? 'bold'
                  : 'normal',
              },
            }}
          >
            {range.label}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default DefinedRanges;
