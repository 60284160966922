import { format } from 'date-fns';
import { Session } from '../../../../types';

interface ReturnType {
  date: string;
  startedAt: Date;
  items: Array<{
    name: string;
    value: number;
  }>;
}

const sortSessionsByDate = (sessions: Session[]) => {
  const sessionsCopy = [...sessions];
  return sessionsCopy.sort((firstSession, secondSession) => {
    const dateA = new Date(firstSession.startedAt);
    const dateB = new Date(secondSession.startedAt);
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });
};

export const groupSessionsByDate = (sessions: Session[]): ReturnType[] => {
  const groupedData = {} as any;
  sortSessionsByDate(sessions).forEach((session: Session) => {
    const startDate: string = format(new Date(session.startedAt), 'dd/MM');
    if (!groupedData[startDate]) {
      groupedData[startDate] = {
        date: startDate,
        startedAt: session.startedAt,
        items: [],
      };
    }
    groupedData[startDate].items.push({
      name: session.roomSessionId,
      value: session.totalStreamerMinutes,
    });
  });
  return Object.values(groupedData);
};
