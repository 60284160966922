import { Error as ErrorIcon } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import EmptyState from 'components/EmptyState';
import RetryCard from 'components/RetryCard';
import { useContext } from 'react';
import Context from 'modules/VoD/Context';
import ListHeader from './components/ListHeader';
import ListItem from './components/ListItem';
import ListItemSkeleton from './components/ListItemSkeleton';
import { getMostLikedVideos } from './services';

const MostLikedVideos = () => {
  const { allStatesValue, dateRange, selectedState } = useContext(Context);
  const newSelectedState =
    selectedState === allStatesValue ? '' : selectedState;
  const {
    isLoading,
    data: videos,
    isError,
    refetch,
  } = useQuery(
    [
      'mostLikedVideos',
      dateRange.startDate.toISOString(),
      dateRange.endDate.toISOString(),
      selectedState,
    ],
    async () =>
      await getMostLikedVideos({ dateRange, selectedState: newSelectedState }),
  );
  const emptyList = !isLoading && videos && !videos.length;
  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Typography sx={{ color: 'neutral.body', fontWeight: 600, mb: 4 }}>
          Vídeos mais curtidos
        </Typography>
        {isError && <RetryCard onRetry={refetch} />}
        {!emptyList && !isError && <ListHeader />}
        {emptyList && !isError && (
          <EmptyState
            icon={ErrorIcon}
            title="Sem registro"
            description="Nenhum vídeo registrado no sistema."
          />
        )}
        {isLoading && !videos
          ? [...Array(3)].map((_, index: number) => (
              <ListItemSkeleton key={`ListItemSkeleton-${index}`} />
            ))
          : videos?.map((currentVideo) => (
              <ListItem key={currentVideo.videoId} video={currentVideo} />
            ))}
      </CardContent>
    </Card>
  );
};

export default MostLikedVideos;
