import {
  BarChartRounded as ChartIcon,
  SearchOff as SearchOffIcon,
} from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import EmptyState from 'components/EmptyState';
import RetryCard from 'components/RetryCard';
import { useContext } from 'react';
import { getSessions } from '../services/session';
import Context from '../Context';
import RoomItem from './RoomItem';
import RoomSkeleton from './skeletons/Room';

const RoomList = () => {
  const { dateRange, searchName } = useContext(Context);
  const {
    isLoading,
    data: rooms,
    isError,
    refetch,
  } = useQuery(
    ['getSessions', dateRange, searchName],
    async () =>
      await getSessions(dateRange.startDate, dateRange.endDate, searchName),
  );
  if (isLoading || !rooms) {
    return <RoomSkeleton />;
  }
  if (isError) {
    return (
      <Card sx={{ mt: 3 }}>
        <RetryCard
          description="Não foi possível encontrar as salas, tente novamente"
          onRetry={refetch}
        />
      </Card>
    );
  }
  if (rooms.length === 0 && searchName) {
    return (
      <EmptyState
        icon={SearchOffIcon}
        title="Sem resultado"
        description="Nenhum resultado encontrado, tente novamente com outra palavra-chave."
        sx={{ mt: 3 }}
      />
    );
  }
  if (rooms.length === 0) {
    return (
      <EmptyState
        icon={ChartIcon}
        title="Sem registros"
        description="Crie seu primeiro estúdio de transmissão para registrar os dados analíticos."
        sx={{ mt: 3 }}
      />
    );
  }
  return (
    <Box sx={{ mt: 4 }}>
      {rooms.map((currentRoom) => (
        <RoomItem key={currentRoom.roomName} room={currentRoom} />
      ))}
    </Box>
  );
};

export default RoomList;
