import { AxiosRequestConfig } from 'axios';
import { redirectToLogin } from 'helpers/axios/axios';
import Cookies from 'js-cookie';

const addSpallaToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const spallaToken = Cookies.get('spalla_token') as string;
  const hasNoSpallaToken = spallaToken === undefined;
  if (hasNoSpallaToken) {
    redirectToLogin();
  }
  if (config.headers != null) {
    config.headers.Authorization = `Bearer ${spallaToken}`;
  }
  return config;
};

export default addSpallaToken;
