import { Participant } from '../types';

type ParticipantWithName = Participant & {
  name: string;
};

export const addNameOnParticipantList = (
  participantListWithoutRole: Participant[],
): ParticipantWithName[] => {
  let hostCounter = 0;
  let participantCounter = 0;
  const fetchParticipantDuration = (
    participantID: Participant['participantID'],
  ) =>
    participantListWithoutRole.find(
      (participant) => participant.participantID === participantID,
    )?.duration ?? 0;
  const withNames = participantListWithoutRole.map(
    (participant: Participant) => {
      if (participant.role === 'host') {
        hostCounter++;
        return {
          ...participant,
          duration: fetchParticipantDuration(participant.participantID),
          name: `Moderador #${hostCounter}`,
        };
      } else {
        participantCounter++;
        return {
          ...participant,
          duration: fetchParticipantDuration(participant.participantID),
          name: `Participante #${participantCounter}`,
        };
      }
    },
  );

  return withNames;
};

export default addNameOnParticipantList;
