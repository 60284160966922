import { format } from 'date-fns';
import { axios } from 'helpers/axios/axios';
import { Participant, Room } from '../types';
import { sessionGroupedByRoom } from '../utils/sessionGroupedByRoom';

export const getSessions = async (
  startDate: Date,
  endDate: Date,
  roomName: string,
): Promise<Room[]> => {
  const start = format(new Date(startDate), 'yyyy-MM-dd');
  const end = format(new Date(endDate), 'yyyy-MM-dd');
  return await axios
    .get(
      `/bff/whereby/insights/sessions?startDate=${start}&endDate=${end}&name=${roomName}`,
    )
    .then(({ data }) => sessionGroupedByRoom(data));
};

export const getParticipants = async (
  sessionId: string,
): Promise<Participant[]> =>
  await axios
    .get(`bff/whereby/insights/participants?roomSessionID=${sessionId}`)
    .then(({ data }) => data.participants);
