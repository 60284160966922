import { Card, CardContent, Skeleton, Stack,  } from '@mui/material';

const ConsumptionCardSkeleton = () => (
  <Card
    sx={{
      height: 153,
      width: 'calc(100% - 16px)',
    }}
  >
    <CardContent>
      <Stack alignItems="center" direction="row" gap={2}>
        <Skeleton variant="rounded" width={25} height={25} />
        <Skeleton variant="text" width={150} height={25} />
      </Stack>
      <Stack alignItems="center" direction="row" gap={7} sx={{ mt: 4 }}>
        <Stack gap={2}>
          <Skeleton variant="text" width={150} height={15} />
          <Skeleton variant="text" width={150} height={15} />
        </Stack>
        <Stack gap={2}>
          <Skeleton variant="text" width={150} height={15} />
          <Skeleton variant="text" width={150} height={15} />
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default ConsumptionCardSkeleton;
