import { Components, Theme } from '@mui/material';

const makeMuiTypography = (theme: Theme): Components['MuiTypography'] => ({
  defaultProps: { color: theme.palette.neutral.body },
  styleOverrides: {
    h3: { fontSize: theme.typography.h5.fontSize, fontWeight: 600 },
  },
});

export default makeMuiTypography;
