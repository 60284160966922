/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContext } from 'react';
import { DateRange } from 'types/Date';

interface ContextType {
  dateRange: DateRange;
  searchName: string;
  setDateRange: (dates: DateRange) => void;
  setSearchName: (name: string) => void;
}

const Context = createContext({} as ContextType);

export default Context;
