import { Route, Routes } from 'react-router-dom';
import Main from './pages/Main';

const StudioModule = () => (
  <Routes>
    <Route path="/" element={<Main />} />
  </Routes>
);

export default StudioModule;
