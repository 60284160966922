import { Button, Stack, Typography } from '@mui/material';

interface Props {
  icon: any;
  title: string;
  description: string;
  isGlobal: boolean;
  onReset: () => void;
}

const ChartSummary = ({
  icon: Icon,
  isGlobal,
  title,
  description,
  onReset,
}: Props) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={1}
      sx={{
        borderRightColor: 'neutral.25',
        borderRightStyle: 'solid',
        borderRightWidth: { mobile: 0, desktop: 1 },
        height: '100%',
        width: { mobile: '100%', desktop: '20%' },
        pr: { desktop: 2 },
      }}
    >
      <Icon sx={{ color: 'neutral.muted' }} />
      <Typography sx={{ color: 'neutral.body', fontSize: 20, fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography
        sx={{
          borderBottom: 1,
          borderColor: 'neutral.25',
          color: 'neutral.50',
          fontSize: 14,
          maxWidth: 153,
          textAlign: 'center',
        }}
      >
        {description}
      </Typography>
      <Button
        variant="outlined"
        disabled={isGlobal}
        sx={{ height: 30, mt: 1, width: '90%' }}
        onClick={onReset}
      >
        Redefinir
      </Button>
    </Stack>
  );
};

export default ChartSummary;
