import { Components } from '@mui/material';

const makeMuiPagination = (): Components['MuiPagination'] => ({
  defaultProps: {
    siblingCount: 6,
    shape: 'rounded',
    showFirstButton: true,
    showLastButton: true,
  },
  styleOverrides: { ul: { justifyContent: 'center' } },
});

export default makeMuiPagination;
