import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { formatMinutesFromHours } from 'utils/time';
import { Room } from '../types';
import RoomName from './RoomName';
import SessionList from './SessionList';

interface Props {
  room: Room;
}

const RoomItem = ({ room }: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      expanded={expanded}
      sx={{
        borderRadius: 1,
        bgcolor: 'transparent',
        mt: 4,
        '&::before': { height: 0 },
        '&.Mui-expanded': { m: 0, mt: 4 },
        '&:first-of-type': { mt: 0 },
        '&.MuiPaper-root .MuiButtonBase-root:hover': {
          cursor: 'default',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <Button
            endIcon={
              <ExpandMoreIcon
                sx={{
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform .3s ease',
                }}
              />
            }
            sx={{
              bgcolor: 'neutral.light',
              color: 'neutral.body',
              cursor: 'pointer !important',
              fontSize: 12,
              fontWeight: 400,
              height: 24,
              ':hover': { bgcolor: 'neutral.light' },
            }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Ocultar detalhes' : 'Mais detalhes'}
          </Button>
        }
        sx={{
          bgcolor: 'common.white',
          height: { desktop: 56 },
          '&.Mui-expanded': { minHeight: { desktop: 56 } },
          px: { mobile: 4, desktop: 5 },
          userSelect: 'text',
          '.MuiAccordionSummary-content': {
            my: 3,
            '&.Mui-expanded': { my: 3 },
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            bottom: { mobile: 16, desktop: 'initial' },
            position: 'absolute',
            right: { mobile: 16, desktop: 24 },
          },
          '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'none',
          },
        }}
      >
        <Stack
          alignItems={{ desktop: 'center' }}
          direction={{ desktop: 'row' }}
          gap={4}
          sx={{ width: { mobile: '100%', desktop: 'auto' } }}
        >
          <RoomName name={room.liveName} />
          <Stack
            alignItems={{ desktop: 'center' }}
            direction={{ desktop: 'row' }}
            gap={{ desktop: 2 }}
          >
            <Typography sx={{ color: 'neutral.body', fontSize: 12 }}>
              Duração total:{' '}
              <Typography
                component="span"
                display="inline"
                sx={{ color: 'inherit', fontSize: 'inherit', fontWeight: 600 }}
              >
                {formatMinutesFromHours(room.totalMinutes)}s
              </Typography>
            </Typography>
            <Typography sx={{ color: 'neutral.body', fontSize: 12 }}>
              Total de participantes:{' '}
              <Typography
                component="span"
                display="inline"
                sx={{ color: 'inherit', fontSize: 'inherit', fontWeight: 600 }}
              >
                {room.totalParticipants}
              </Typography>
            </Typography>
            <Typography sx={{ color: 'neutral.body', fontSize: 12 }}>
              Sessões:{' '}
              <Typography
                component="span"
                display="inline"
                sx={{ color: 'inherit', fontSize: 'inherit', fontWeight: 600 }}
              >
                {room.totalSessions}
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <SessionList expanded={expanded} room={room} />
    </Accordion>
  );
};

export default RoomItem;
