import { useContext, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import Context from '../../../Context';
import { DateRange } from '../../../types';
import getWordsInterest from '../services/getWordsInterest';
import getWordsInterestByVod from '../services/getWordsInterestByVod';
import EmptyList from './EmptyList';
import WordsInterestItem from './WordsInterestItem';
import WordsInterestSkeletons from './WordsInterestSkeletons';

interface WordsInterestListProps {
  allStatesValue: string;
  dateRange: DateRange;
  selectedState: string;
}

const WordsInterestList = ({
  allStatesValue,
  dateRange,
  selectedState,
}: WordsInterestListProps) => {
  const { isDir, itemId } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);

  const isNewSelectedStateAll = selectedState === allStatesValue;
  const newSelectedState = isNewSelectedStateAll ? '' : selectedState;

  const { isLoading, data: getWordsInterestRes } = useQuery(
    [
      'getWordsInterest',
      itemId,
      newSelectedState,
      dateRange.startDate.toISOString(),
      dateRange.endDate.toISOString(),
    ],
    async () => {
      const request = isDir
        ? getWordsInterest(itemId, newSelectedState, dateRange)
        : getWordsInterestByVod(itemId, newSelectedState, dateRange);
      return await request;
    },
  );

  const calculatePercentage = (totalSum: number, total: number) =>
    totalSum !== 0 ? (total / totalSum) * 100 : 0;

  const renderWordsInterestItems = () => {
    if (!isLoading && getWordsInterestRes && getWordsInterestRes.length > 0) {
      const totalSum = getWordsInterestRes.reduce(
        (accumulator, wordInterest) => accumulator + wordInterest.total,
        0,
      );

      const sortedWordsInterest = getWordsInterestRes.slice().sort((a, b) => {
        const percentageA = calculatePercentage(totalSum, a.total);
        const percentageB = calculatePercentage(totalSum, b.total);
        return percentageB - percentageA;
      });

      const itemsPerPage = 5;
      const totalItems = sortedWordsInterest.length;
      const totalPages = Math.ceil(totalItems / itemsPerPage);

      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = sortedWordsInterest.slice(
        indexOfFirstItem,
        indexOfLastItem,
      );

      const continuousIndex = (currentPage - 1) * itemsPerPage;

      return (
        <>
          <Stack alignItems="center" direction="row" gap={1}>
            <Typography sx={{ color: 'neutral.body', fontWeight: 600, mb: 2 }}>
              Palavras de interesse
            </Typography>
          </Stack>
          {!isLoading &&
            currentItems.map((wordInterest, index) => {
              const percentage = calculatePercentage(
                totalSum,
                wordInterest.total,
              );
              return (
                <WordsInterestItem
                  key={index}
                  word={{
                    term: wordInterest.term,
                    total: wordInterest.total,
                  }}
                  index={continuousIndex + index + 1}
                  percentage={percentage}
                />
              );
            })}
          <Stack alignItems="center" direction="row" justifyContent="flex-end">
            <IconButton onClick={goToPreviousPage} disabled={currentPage === 1}>
              <KeyboardArrowLeft />
            </IconButton>
            <Typography
              sx={{ color: 'neutral.50', fontSize: 16, fontWeight: 400 }}
            >
              {`${currentPage} de ${totalPages}`}
            </Typography>
            <IconButton
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Stack>
        </>
      );
    }
    return <EmptyList />;
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const renderLoadingSkeletons = () => (
    <Card>
      <CardContent>
        <Grid container gap={2} sx={{ mt: 4 }}>
          <Grid item desktop={true}>
            {[...Array(6)].map((_, index) => (
              <WordsInterestSkeletons key={index} />
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return isLoading ? (
    renderLoadingSkeletons()
  ) : (
    <Card>
      <CardContent>{renderWordsInterestItems()}</CardContent>
    </Card>
  );
};

export default WordsInterestList;
