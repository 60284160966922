import { CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Context from '../../../Context';
import getTotalViewsByDate from '../services/getTotalViewsByDate';
import getTotalViewsByFolderDate from '../services/getTotalViewsByFolderDate';
import LineChart from './LineChart';

const TotalViewsByDateChart = () => {
  const { allStatesValue, itemId, isDir, dateRange, selectedState } =
    useContext(Context);
  const newSelectedState =
    selectedState === allStatesValue ? '' : selectedState;
  const { isLoading, data } = useQuery(
    [
      'TotalViewsByDate',
      itemId,
      dateRange.startDate.toISOString(),
      dateRange.endDate.toISOString(),
      selectedState,
    ],
    async () => {
      const request = isDir ? getTotalViewsByFolderDate : getTotalViewsByDate;
      const props = { itemId, dateRange, selectedState: newSelectedState };
      return await request({ ...props });
    },
  );
  const isLoadingData = isLoading || data === undefined;
  if (isLoadingData) return <CircularProgress />;
  return (
    <Stack>
      <LineChart data={data} />
    </Stack>
  );
};

export default TotalViewsByDateChart;
