import { LinearProgress, Skeleton, Stack, Typography } from '@mui/material';

const WordsInterestItemSkeleton = () => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ mb: 6 }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Skeleton variant="rounded" height={32} width={32} sx={{ mr: 3, }} />
        <Typography
          sx={{
            color: 'neutral.body',
            fontSize: 14,
            maxWidth: '16vw',
            mb: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Skeleton variant="text" width={100} />
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Typography sx={{
          color: 'neutral.50',
          fontFamily: 'Poppins',
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 400,
          mr: 2,
        }}>
          <Skeleton variant="text" width={20} />
        </Typography>
        <LinearProgress
          variant="determinate"
          value={0}
          sx={{
            height: 10,
            minWidth: 126,
            ml: 2,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default WordsInterestItemSkeleton;
