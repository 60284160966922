import { axios } from 'helpers/axios/axios';
import { Vod } from 'modules/VoD/interfaces/vod';
import formatBaseURLWithDateRange from 'modules/VoD/utils/formatBaseURLWithDateRange';
import { DateRange } from 'types/Date';

export interface GetMostLikedVideosProps {
  dateRange: DateRange;
  selectedState: string;
}

export const getMostLikedVideos = async ({
  dateRange,
  selectedState,
}: GetMostLikedVideosProps): Promise<Vod[]> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  return await axios
    .get(`/v1/analytics/vod/most-liked${dateFilter}&region=${selectedState}`)
    .then(({ data }) => data);
};
