import { Stack } from '@mui/material';
import { ReactNode } from 'react';

const MainContainer = ({
  menuIsSticked,
  children,
}: {
  menuIsSticked?: boolean;
  children: ReactNode;
}) => (
  <Stack
    sx={{
      ml: { desktop: menuIsSticked ? '264px' : '70px' },
      mt: 4,
      width: '100%',
    }}
  >
    <Stack sx={{ px: { desktop: 4 } }}>{children}</Stack>
  </Stack>
);

export default MainContainer;
