import {
  Info as InfoIcon,
  List as ConsumptionIcon,
  Lock as LockIcon,
} from '@mui/icons-material';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import ConsumptionCardItem from './ConsumptionCardItem';

interface Props {
  transcoding: string;
  storage: string;
  disabled?: boolean;
}

const ConsumptionCard = ({ disabled = false, transcoding, storage }: Props) => (
  <Card
    sx={{
      cursor: disabled ? 'not-allowed' : 'default',
      width: 'calc(100% - 16px)',
    }}
  >
    <CardContent>
      <Stack alignItems="center" direction="row" gap={2}>
        {disabled ? (
          <LockIcon sx={{ color: 'neutral.muted' }} />
        ) : (
          <ConsumptionIcon color="primary" />
        )}
        <Typography
          variant="h6"
          sx={{
            color: disabled ? 'neutral.muted' : 'neutral.body',
            fontWeight: 600,
          }}
        >
          Consumido
        </Typography>
      </Stack>
      <Stack alignItems="center" direction="row" gap={7} sx={{ mt: 4 }}>
        <ConsumptionCardItem
          disabled={disabled}
          title="Transcoding"
          value={transcoding}
        />
        <ConsumptionCardItem
          disabled={disabled}
          title="Armazenamento"
          value={storage}
        />
      </Stack>
      <Stack alignItems="center" direction="row" gap={1}>
        <InfoIcon sx={{ color: 'neutral.50',  width: 13 }} />
        <Typography sx={{ color: 'neutral.50', fontSize: 12 }}>
          Referente ao período completo dos meses selecionados
        </Typography>
      </Stack>
    </CardContent>
  </Card>
);

export default ConsumptionCard;
