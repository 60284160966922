import * as am5 from '@amcharts/amcharts5';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import * as am5percent from '@amcharts/amcharts5/percent';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useLayoutEffect, useMemo } from 'react';
import { StatisticsValues } from '../types';
import SubtitleLabel from './SubtitleLabel';

interface Subtitle {
  id: number;
  color: string;
  label: string;
}

interface ChartData {
  blueData: StatisticsValues;
  purpleData: StatisticsValues;
  yellowData: StatisticsValues;
  greenData: StatisticsValues;
  redData: StatisticsValues;
  lightBlueData?: StatisticsValues;
  lightGreenData?: StatisticsValues;
  pinkData?: StatisticsValues;
}
interface DonutChartProps {
  hasLabel: boolean;
  scale: number;
  title: string;
  subtitles: Subtitle[];
  chartData: ChartData;
}

const DonutChart = ({
  chartData,
  hasLabel,
  scale,
  subtitles,
  title,
}: DonutChartProps) => {
  const { palette } = useTheme();
  const {
    blueData,
    greenData,
    lightBlueData,
    lightGreenData,
    pinkData,
    purpleData,
    redData,
    yellowData,
  } = chartData;
  const dataKeys = Object.keys(chartData);
  const hasNoData = dataKeys.every(
    (key) => !chartData[key as keyof ChartData]?.value,
  );
  const subtitleValues = dataKeys.map((key) => ({
    id: chartData[key as keyof ChartData]?.id,
    value: chartData[key as keyof ChartData]?.value,
  }));
  const subtitleIsVisible = (subtitle: Subtitle) => {
    const findSubtitle = subtitleValues.find(
      (currentSubtitle) => currentSubtitle.id === subtitle.id,
    )?.value;
    return !!findSubtitle;
  };
  const getSubtitleData = (subtitle: Subtitle) => {
    const subtitles = Object.values(chartData);
    const findSubtitle = subtitles.find(
      (currentSubtitle) => currentSubtitle.id === subtitle.id,
    );
    return findSubtitle;
  };
  const memoizedSubtitleList = useMemo(
    () =>
      subtitles.map((subtitle) => (
        <SubtitleLabel
          key={subtitle.color}
          color={subtitle.color}
          label={subtitle.label}
          isVisible={subtitleIsVisible(subtitle)}
          percentage={getSubtitleData(subtitle).percents}
        />
      )),
    [subtitles],
  );
  useLayoutEffect(() => {
    const root: am5.Root = am5.Root.new(`chartdiv-${title}`);
    root.setThemes([am5themesAnimated.new(root)]);
    root.numberFormatter.setAll({ numberFormat: '#,###.00' });
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      }),
    );
    const series1 = chart.series.push(
      am5percent.PieSeries.new(root, {
        radius: am5.percent(60),
        innerRadius: am5.percent(85),
        valueField: 'value',
        alignLabels: false,
        tooltip: am5.Tooltip.new(root, {
          forceHidden: hasNoData,
        }),
      }),
    );
    series1.labels.template.set('visible', hasLabel && !hasNoData);
    series1.states.create('hidden', {
      startAngle: 180,
      endAngle: 180,
    });
    series1.slices.template.setAll({
      templateField: 'sliceSettings',
      strokeOpacity: 1,
      strokeWidth: 2,
      stroke: am5.color(palette.common.white),
      tooltipHTML: hasLabel
        ? '<span>{value}</span>'
        : '<span>{percents}%</span>',
    });
    series1.labels.template.setAll({
      fontSize: 10,
      fill: am5.color(palette.common.black),
      radius: 35,
      text: '{percents.numberFormat(#.00)}%',
    });
    series1.slices.template.states.create('hover', { scale });
    series1.slices.template.states.create('active', { shiftRadius: 0 });
    series1.ticks.template.setAll({
      forceHidden: !hasLabel,
    });
    if (root._logo != null) {
      root._logo.dispose();
    }
    const dataToSet = hasNoData
      ? [
          {
            percents: 100,
            value: 100,
            sliceSettings: { fill: am5.color(palette.neutral[25]) },
          },
        ]
      : [
          {
            percents: purpleData.percents,
            value: purpleData.value,
            sliceSettings: { fill: am5.color(palette.info.main) },
          },
          {
            percents: yellowData.percents,
            value: yellowData.value,
            sliceSettings: { fill: am5.color(palette.warning.main) },
          },
          {
            percents: greenData.percents,
            value: greenData.value,
            sliceSettings: { fill: am5.color(palette.success.main) },
          },
          {
            percents: redData.percents,
            value: redData.value,
            sliceSettings: { fill: am5.color(palette.error.main) },
          },
          {
            percents: blueData.percents,
            value: blueData.value,
            sliceSettings: { fill: am5.color(palette.primary.main) },
          },
          {
            percents: lightBlueData?.percents,
            value: lightBlueData?.value,
            sliceSettings: { fill: am5.color('#BCE2FF') },
          },
          {
            percents: lightGreenData?.percents,
            value: lightGreenData?.value,
            sliceSettings: { fill: am5.color('#B1DEDA') },
          },
          {
            percents: pinkData?.percents,
            value: pinkData?.value,
            sliceSettings: { fill: am5.color('#FFAAB2') },
          },
        ];
    const removedZero = dataToSet.filter((data) => !!data.value);
    series1.data.setAll(removedZero);
    return () => root?.dispose();
  }, []);
  return (
    <Stack alignItems="center" direction="row">
      <Stack>
        <Typography sx={{ color: 'neutral.dark', fontWeight: 600, ml: 6 }}>
          {title}
        </Typography>
        <Box id={`chartdiv-${title}`} sx={{ maxWidth: 291, minHeight: 291 }} />
      </Stack>
      <Stack gap={3}>
        {memoizedSubtitleList}
        {hasNoData && (
          <SubtitleLabel
            color="neutral.25"
            label="Sem dados"
            isVisible={true}
            percentage={100}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default DonutChart;
