import { Info as InfoIcon } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Player from 'components/Player';
import TabPanel from 'components/TabPanel';
import { SyntheticEvent, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Context from '../../Context';
import { Devices } from '../../interfaces/device';
import AverageWatchedtimeChart from './components/AverageWatchedtimeChart';
import DonutChart from './components/DonutChart';
import DonutChartSkeleton from './components/DonutChartsSkeleton';
import MostUsedDevicesChart from './components/MostUsedDevicesChart';
import MostUsedDevicesChartSkeleton from './components/MostUsedDevicesChartSkeleton';
import RentemptionByMinute from './components/RentemptionByMinute';
import TotalImpressionsByDateChart from './components/TotalImpressionsByDateChart';
import TotalUniqueViewersChart from './components/TotalUniqueViewersChart';
import TotalViewsByDateChart from './components/TotalViewsByDateChart';
import TotalWatchedTimeChart from './components/TotalWatchedTimeChart';
import getFolderStatisticsInfo, {
  FolderInfo,
} from './services/getFolderStaticsInfo';
import getVodStatisticsInfo, {
  ContentInfo,
  SpeedProps,
} from './services/getVodStaticsInfo';
import { qualityMostUseds, speedsMostUseds } from './providers';
import getVod from './services/getVod';
import { StandardResolutions, StandardSpeeds } from './types';

interface StatisticsResponse {
  isLoading: boolean;
  data: ContentInfo & FolderInfo;
}

const Statistics = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { allStatesValue, itemId, dateRange, selectedState } =
    useContext(Context);
  const [params] = useSearchParams();
  const isDir = params.get('isDir') === 'true';
  const { isLoading: isLoadingVideo, data: vod } = useQuery(
    [itemId, 'vod'],
    async () => !isDir && (await getVod(itemId)),
  );
  const newSelectedState =
    selectedState === allStatesValue ? '' : selectedState;
  const { isLoading, data } = useQuery(
    [
      'vodStatistics',
      itemId,
      dateRange.startDate.toISOString(),
      dateRange.endDate.toISOString(),
      selectedState,
    ],
    async () => {
      const request = isDir
        ? getFolderStatisticsInfo({
            folderId: itemId,
            dateRange,
            selectedState: newSelectedState,
          })
        : getVodStatisticsInfo({
            contentId: itemId,
            dateRange,
            selectedState: newSelectedState,
          });
      return await request;
    },
  ) as StatisticsResponse;
  const handleChange = (event: SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };
  const mostUsedResolutions = data?.mostUsedResolutions?.resolutions;
  const totalSpeeds = data?.totalSpeeds;
  const getChartPercentageLabel = (
    value: number | undefined,
    total: number | undefined,
  ) => (total !== undefined && value !== undefined ? (value / total) * 100 : 0);
  const standardSpeeds: StandardSpeeds = {
    0.5: { id: 8, value: 0, percents: 0 },
    0.75: { id: 7, value: 0, percents: 0 },
    1: { id: 6, value: 0, percents: 0 },
    1.25: { id: 5, value: 0, percents: 0 },
    1.5: { id: 4, value: 0, percents: 0 },
    1.75: { id: 3, value: 0, percents: 0 },
    2: { id: 2, value: 0, percents: 0 },
    2.5: { id: 1, value: 0, percents: 0 },
  };
  let totalSpeedsValuesSum = 0;
  const resolutionValues: number[] =
    mostUsedResolutions != null ? Object.values(mostUsedResolutions) : [];
  const sumWithInitial = resolutionValues.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0,
  );
  const hasMostUsedResolutions = mostUsedResolutions !== undefined;
  const getResolutionPercentage = (value: number | undefined) =>
    hasMostUsedResolutions ? getChartPercentageLabel(value, sumWithInitial) : 0;
  const standardResolutions: StandardResolutions = {
    240: {
      id: 8,
      value: mostUsedResolutions?.[240],
      percents: getResolutionPercentage(mostUsedResolutions?.[240]),
    },
    360: {
      id: 7,
      value: mostUsedResolutions?.[360],
      percents: getResolutionPercentage(mostUsedResolutions?.[360]),
    },
    480: {
      id: 6,
      value: mostUsedResolutions?.[480],
      percents: getResolutionPercentage(mostUsedResolutions?.[480]),
    },
    720: {
      id: 4,
      value: mostUsedResolutions?.[720],
      percents: getResolutionPercentage(mostUsedResolutions?.[720]),
    },
    1080: {
      id: 1,
      value: mostUsedResolutions?.[1080],
      percents: getResolutionPercentage(mostUsedResolutions?.[1080]),
    },
  };
  const getTotalSpeedsSum = totalSpeeds?.map(
    (speed) => (totalSpeedsValuesSum += speed.total),
  );
  totalSpeeds?.forEach((mostUsedSpeed: SpeedProps) => {
    if (
      Object.prototype.hasOwnProperty.call(standardSpeeds, mostUsedSpeed.speed)
    ) {
      const standardSpeedsKey =
        mostUsedSpeed.speed as keyof typeof standardSpeeds;
      standardSpeeds[standardSpeedsKey].value = mostUsedSpeed.total;
      standardSpeeds[standardSpeedsKey].percents = getChartPercentageLabel(
        standardSpeeds[standardSpeedsKey].value,
        getTotalSpeedsSum?.[getTotalSpeedsSum.length - 1],
      );
    }
  });
  const mostUsedDevices: Record<Devices, number> = {
    mobile: data?.mostUsedDevices.devices.mobile ?? 0,
    tablet: data?.mostUsedDevices.devices.tablet ?? 0,
    desktop: data?.mostUsedDevices.devices.desktop ?? 0,
  };
  const chartsComponents = [
    {
      label: 'Visualizações',
      tooltipMessage:
        'Informa o total de visualizações do conteúdo após um período de tempo',
      Component: TotalViewsByDateChart,
    },
    {
      label: 'Impressões',
      tooltipMessage: 'Informa o total de vezes que o conteúdo foi acessado',
      Component: TotalImpressionsByDateChart,
    },
    {
      label: 'Espectadores únicos',
      tooltipMessage:
        'Informa o total de pessoas que visualizaram o conteúdo sem contabilizar repetições',
      Component: TotalUniqueViewersChart,
    },
    {
      label: 'Tempo consumido',
      tooltipMessage: 'Informa a média de tempo assistido por cada acesso',
      Component: TotalWatchedTimeChart,
    },
    {
      label: 'Duração da visualização',
      tooltipMessage:
        'Informa a média de tempo assistido por um usuário único, incluindo o tempo médio de múltiplos acessos',
      Component: AverageWatchedtimeChart,
    },
  ];
  const mostUsedQualitiesValues = {
    blueData: standardResolutions[240],
    purpleData: standardResolutions[1080],
    yellowData: standardResolutions[720],
    greenData: standardResolutions[480],
    redData: standardResolutions[360],
  };
  const mostUsedSpeedValues = {
    blueData: standardSpeeds[0.5],
    purpleData: standardSpeeds[2.5],
    yellowData: standardSpeeds[1.5],
    greenData: standardSpeeds[1],
    redData: standardSpeeds[0.75],
    pinkData: standardSpeeds[1.75],
    lightBlueData: standardSpeeds[2],
    lightGreenData: standardSpeeds[1.25],
  };
  const secondsToHms = (valueInSeconds: number) => {
    const totalSeconds = Number(valueInSeconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${hours}h${minutes}min${seconds}s`;
  };
  const formatPercentage = (value: number) => `${value?.toFixed(1)}%`;
  const formatValueOrSkeleton = (value: string, isLoading: boolean) => {
    return isLoading ? (
      <Skeleton variant="text" sx={{ fontWeight: 600 }} />
    ) : (
      value
    );
  };
  const averageWatchedTimeInSeconds =
    data?.averageWatchedTime?.durationInSeconds ?? 0;
  const formattedRetentionTime = secondsToHms(averageWatchedTimeInSeconds);
  const averageWatchedTimePercentage =
    data?.averageWatchedTimePercentage?.durationInPercentage ?? 0;
  const formattedRetentionPercentage = formatPercentage(
    averageWatchedTimePercentage,
  );
  const retentionCardLabels = [
    {
      title: 'Tempo médio de visualização',
      value: formatValueOrSkeleton(formattedRetentionTime, isLoading),
    },
    {
      title: 'Visualização média',
      value: formatValueOrSkeleton(formattedRetentionPercentage, isLoading),
    },
  ];
  return (
    <>
      {!isDir && (
        <Card sx={{ height: 375, mt: 4, p: 4 }}>
          <CardContent sx={{ height: '100%' }}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{ height: '100%' }}
            >
              <Stack sx={{ height: '100%', width: '50%' }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={1}
                  sx={{ mb: -4 }}
                >
                  <Typography sx={{ color: 'neutral.body', fontWeight: 600 }}>
                    Retenção de público
                  </Typography>
                  <Tooltip title="A porcentagem pode ultrapassar de 100% pois o espectador pode assistir novamente um trecho.">
                    <InfoIcon sx={{ color: 'neutral.muted' }} />
                  </Tooltip>
                </Stack>
                {isLoading ? (
                  <Stack sx={{ mt: 5 }}>
                    {[...Array(7)].map(() => (
                      <Skeleton
                        key={Math.random()}
                        variant="text"
                        sx={{ height: 30 }}
                      />
                    ))}
                  </Stack>
                ) : (
                  <RentemptionByMinute />
                )}
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={6}
                  sx={{ mt: 2 }}
                >
                  {retentionCardLabels?.map((label) => (
                    <Stack key={label.title}>
                      <Typography sx={{ color: 'neutral.50', fontSize: 14 }}>
                        {label.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'neutral.body',
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      >
                        {label.value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Box sx={{ width: 516 }}>
                {isLoadingVideo ? (
                  <CircularProgress />
                ) : (
                  <Player
                    source={{
                      sources: [
                        {
                          src: vod.assets.hls,
                          type: 'application/x-mpegurl',
                        },
                      ],
                    }}
                  />
                )}
              </Box>
            </Stack>
          </CardContent>
        </Card>
      )}
      <Card sx={{ height: 337, mt: 4 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            {isLoading ? (
              <>
                <DonutChartSkeleton />
                <DonutChartSkeleton />
                <MostUsedDevicesChartSkeleton />
              </>
            ) : (
              <>
                <DonutChart
                  chartData={mostUsedQualitiesValues}
                  hasLabel={false}
                  scale={1.05}
                  subtitles={qualityMostUseds}
                  title="Qualidades mais utilizadas"
                />
                <DonutChart
                  chartData={mostUsedSpeedValues}
                  hasLabel={false}
                  scale={1.05}
                  subtitles={speedsMostUseds}
                  title="Velocidades mais utilizadas"
                />
                <MostUsedDevicesChart devices={mostUsedDevices} />
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Card sx={{ height: 337, mt: 4 }}>
        <CardContent>
          {isLoading ? (
            <Stack gap={8}>
              <Stack alignItems="center" direction="row" gap={5}>
                {[...Array(4)].map(() => (
                  <Skeleton
                    key={Math.random()}
                    variant="text"
                    sx={{ height: 30, width: 100 }}
                  />
                ))}
              </Stack>
              <Stack>
                {[...Array(4)].map(() => (
                  <Skeleton
                    key={Math.random()}
                    variant="text"
                    sx={{ height: 30 }}
                  />
                ))}
              </Stack>
            </Stack>
          ) : (
            <>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleChange}>
                  {chartsComponents.map(({ label, tooltipMessage }, index) => (
                    <Tab
                      key={`tab-${index}`}
                      icon={
                        <Tooltip title={tooltipMessage} placement="top">
                          <InfoIcon
                            sx={{
                              color: 'neutral.muted',
                            }}
                          />
                        </Tooltip>
                      }
                      iconPosition="end"
                      label={label}
                    />
                  ))}
                </Tabs>
              </Box>
              {chartsComponents.map(({ Component }, index) => (
                <TabPanel key={`chart-${index}`} value={tabIndex} index={index}>
                  <Component />
                </TabPanel>
              ))}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Statistics;
