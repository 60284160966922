/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { AccordionDetails } from '@mui/material';
import { WatchLater as WatchLaterIcon } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import EmptyState from 'components/EmptyState';
import RetryCard from 'components/RetryCard';
import ParticipantTableSkeleton from '../components/skeletons/ParticipantTable';
import { getParticipants } from '../services/session';
import ParticipantTable from './ParticipantTable';

interface Props {
  expanded: boolean;
  shouldShowParticipantTable?: boolean;
  sessionId: string;
}

const ParticipantSection = ({
  expanded,
  shouldShowParticipantTable,
  sessionId,
}: Props) => {
  const {
    isLoading,
    data: participants,
    refetch,
    isError,
  } = useQuery(
    ['getParticipants', sessionId, expanded, shouldShowParticipantTable],
    async () => await getParticipants(sessionId),
    {
      enabled: expanded && shouldShowParticipantTable,
    },
  );
  if (!expanded) {
    return <></>;
  }
  if (
    (isLoading && shouldShowParticipantTable) ||
    (!participants && shouldShowParticipantTable)
  ) {
    return <ParticipantTableSkeleton />;
  }
  return (
    <AccordionDetails sx={{ p: 0 }}>
      {isError ? (
        <RetryCard onRetry={refetch} />
      ) : shouldShowParticipantTable ? (
        <ParticipantTable
          sessionId={sessionId}
          participants={participants || []}
        />
      ) : (
        <EmptyState
          icon={WatchLaterIcon}
          title="Aguarde o encerramento da sessão"
          description="As informações dos participantes serão apresentadas após o encerramento da sessão."
          sx={{ height: 202 }}
        />
      )}
    </AccordionDetails>
  );
};

export default ParticipantSection;
