import { Skeleton, Stack } from '@mui/material';

const MostUsedDevicesChartSkeleton = () => (
  <Stack gap={8} sx={{ height: '100%', position: 'relative', width: 251 }}>
    <Skeleton variant="text" sx={{ fontWeight: 600 }} />
    <Stack gap={1}>
      {[...Array(4)].map(() => (
        <Skeleton key={Math.random()} variant="text" sx={{ height: 30, width: 251 }} />
      ))}
    </Stack>
  </Stack>
);

export default MostUsedDevicesChartSkeleton;
