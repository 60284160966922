import { useCallback, useState } from 'react';
import MostLikedVideos from './components/MostLikedVideos';
import MostViewedVods from './components/MostViewedVods';
import RecentlyViewed from './components/RecentlyViewed';
import SelectMetric from './components/SelectMetric';
import WordsInterest from './components/WordsInterest';

const metricsComponents = [
  <MostViewedVods />,
  <WordsInterest />,
  <RecentlyViewed />,
  <MostLikedVideos />,
];

const Popularity = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const changeSelectedTabIndex = useCallback(
    (index: number) => setSelectedTabIndex(index),
    [],
  );
  return (
    <>
      <SelectMetric
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={changeSelectedTabIndex}
      />
      {metricsComponents[selectedTabIndex]}
    </>
  );
};

export default Popularity;
