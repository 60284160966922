import { axios } from 'helpers/axios/axios';
import { Directory } from 'types/Directory';

const getDirectories = async (
  title: string,
  directoryId: string,
): Promise<Directory> =>
  await axios
    .get(`/v1/directories?name=${title}&directory=${directoryId}`)
    .then(({ data }) => data);

export default getDirectories;
