import { KeyboardArrowDown as ArrowIcon } from '@mui/icons-material';
import { Button, Skeleton, Stack } from '@mui/material';

const Room = () => (
  <Stack gap={3}>
    {[...Array(5)].map((_, index) => (
      <Stack
        key={`room-skeleton-${index}`}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          bgcolor: 'common.white',
          borderRadius: 1,
          minHeight: 56,
          position: 'relative',
          px: { mobile: 4, desktop: 5 },
          py: { mobile: 3, desktop: 0 },
        }}
      >
        <Stack>
          <Skeleton
            variant="rounded"
            height={24}
            sx={{ mb: 2, width: { mobile: 290, desktop: 777 } }}
          />
          {[...Array(3)].map((_, index) => (
            <Skeleton
              key={`details-skeleton-${index}`}
              variant="rounded"
              height={18}
              sx={{
                display: { desktop: 'none' },
                mt: 2,
                width: { mobile: 270, desktop: 777 },
              }}
            />
          ))}
        </Stack>
        <Button
          disabled
          sx={{
            bottom: { mobile: 12, desktop: 'initial' },
            color: 'neutral.body',
            fontSize: 12,
            height: 24,
            minWidth: 116,
            position: 'absolute',
            right: { mobile: 16, desktop: 24 },
          }}
          endIcon={<ArrowIcon sx={{ color: 'neutral.50', fontSize: 12 }} />}
        >
          Mais detalhes
        </Button>
      </Stack>
    ))}
  </Stack>
);

export default Room;
