import { Components, Theme } from '@mui/material';

const makeMuiChip = (theme: Theme): Components['MuiChip'] => ({
  defaultProps: { color: 'primary' },
  styleOverrides: {
    root: {
      borderRadius: theme.shape.borderRadius,
      height: theme.shape.size.small,
      '& .MuiChip-deleteIcon': {
        color: theme.palette.neutral[75],
        fontSize: theme.typography.body3.fontSize,
      },
    },
    label: {
      fontSize: theme.typography.body3.fontSize,
    },
  },
  variants: [
    {
      props: { color: 'primary' },
      style: {
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.primary.main,
      },
    },
    {
      props: { color: 'success' },
      style: {
        backgroundColor: theme.palette.success[50],
        color: theme.palette.success.main,
      },
    },
    {
      props: { color: 'error' },
      style: {
        backgroundColor: theme.palette.error[50],
        color: theme.palette.error.main,
      },
    },
    {
      props: { color: 'warning' },
      style: {
        backgroundColor: theme.palette.warning[50],
        color: theme.palette.warning.main,
      },
    },
    {
      props: { color: 'info' },
      style: {
        backgroundColor: theme.palette.info[50],
        color: theme.palette.info.main,
      },
    },
    {
      props: { size: 'small' },
      style: {
        fontSize: theme.typography.body3.fontSize,
        height: theme.shape.size.small,
      },
    },
    {
      props: { size: 'medium' },
      style: {
        fontSize: theme.typography.body2.fontSize,
        height: theme.shape.size.medium,
      },
    },
    {
      props: { size: 'large' },
      style: {
        fontSize: theme.typography.body1.fontSize,
        height: theme.shape.size.large,
      },
    },
  ],
});

export default makeMuiChip;
