/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useQuery } from '@tanstack/react-query';
import { createContext, ReactNode } from 'react';
import getMe, { Me } from 'services/getMe';
import Skeletons from './Skeletons';

interface Props {
  children: ReactNode;
}

interface ContextType {
  me: Me;
}

export const Context = createContext({} as ContextType);

const MeProvider = ({ children }: Props) => {
  const { isLoading, data } = useQuery(['me'], getMe);
  const meIsLoading = isLoading && data === undefined;
  if (meIsLoading) return <Skeletons />;
  const me = data as Me;
  return <Context.Provider value={{ me }}>{children}</Context.Provider>;
};

export default MeProvider;
