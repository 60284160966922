import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Box } from '@mui/material';
import { useLayoutEffect } from 'react';

interface Props {
  data: Array<{
    date: string;
    items: Array<{
      name: string;
      value: number;
    }>;
  }>;
  roomName: string;
  updateSelectedSession: (value: number) => void;
}

interface Item {
  category: string;
  date: string;
  itemName: string;
  value: number;
}

const Chart = ({ data, roomName, updateSelectedSession }: Props) => {
  useLayoutEffect(() => {
    const root: am5.Root = am5.Root.new(`chartdiv-${roomName}`);
    if (root._logo != null) {
      root._logo.dispose();
    }
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingLeft: 0,
      }),
    );

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 0,
    });

    xRenderer.labels.template.setAll({ text: '' });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'category',
        renderer: xRenderer,
      }),
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'min'",
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    yAxis.get('renderer').labels.template.setAll({
      fontSize: 12,
    });

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis,
        yAxis,
        valueYField: 'value',
        sequencedInterpolation: true,
        categoryXField: 'category',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'left',
          html: `<div style="background-color: #FFFFFF; font-family: 'Poppins'; font-size: 12px; width: 100%; padding: 8px; height: 100%">
          <strong style="color: #575757;">{itemName}</strong>
          <div style="color: #575757;  margin-top: 8px">
            Data: <strong style="color: #575757;">{date}</strong>
          </div>
          <div style="color: #575757; margin-top: 8px">
            Duração: <strong style="color: #575757;">{valueY}</strong>
          </div>
        </div>`,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }),
      }),
    );

    root.durationFormatter.setAll({
      baseUnit: 'minute',
      durationFormat: "hh:mm:ss'min'",
      durationFields: ['valueY'],
    });

    series.columns.template.setAll({
      fill: am5.color(0x3699ff),
      fillOpacity: 0.9,
      strokeOpacity: 0,
    });

    series.columns.template.events.on('click', (ev) => {
      if (ev.target.dataItem) {
        console.log(ev.target.dataItem.dataContext);
        const { value } = ev.target.dataItem.dataContext as { value: number };
        updateSelectedSession(value);
      }
    });

    const chartData: Item[] = [];

    data.forEach((currentData) => {
      const { date, items } = currentData;
      const tempArray: Item[] = [];

      items.forEach((item) => {
        tempArray.push({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          category: `${date}_${item.name}`,
          itemName: item.name,
          value: item.value,
          date,
        });
      });

      am5.array.each(tempArray, (item: Item) => chartData.push(item));

      const range = xAxis.makeDataItem({});
      xAxis.createAxisRange(range);

      range.set('category', tempArray[0].category);

      const label = range.get('label');

      if (label) {
        label.setAll({
          text: tempArray[0].date,
          dy: 10,
          fontSize: 12,
          tooltipText: tempArray[0].date,
        });
      }
    });

    xAxis.data.setAll(chartData);
    series.data.setAll(chartData);

    void series.appear(1000);
    void chart.appear(1000, 100);
    return () => root?.dispose();
  }, [data]);
  return (
    <Box
      sx={{
        overflowX: { mobile: 'scroll', desktop: 'hidden' },
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        id={`chartdiv-${roomName}`}
        sx={{
          height: { mobile: 210, desktop: '100%' },
          width: { mobile: 2000, desktop: '100%' },
        }}
      ></Box>
    </Box>
  );
};

export default Chart;
