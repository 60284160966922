import { Components, Theme } from '@mui/material';

const makeMuiOutlinedInput = (
  theme: Theme,
): Components['MuiOutlinedInput'] => ({
  styleOverrides: {
    root: {
      border: `1px solid ${theme.palette.neutral[25]}`,
      fontSize: 14,
      borderRadius: theme.shape.borderRadius,
      transition: 'border .3s ease',
      legend: { display: 'none' },
      fieldset: { display: 'none' },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.neutral[50]}`,
      },
      '& input::placeholder': {
        fontSize: 14,
      },
    },
  },
});

export default makeMuiOutlinedInput;
